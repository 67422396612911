import { combineReducers } from "redux";
import { assignApplicationReducer, toggleHoldForExportReducer, committeeReviewerReducer, notifyAdminReducer, clearNotifyAdminReducer, reassignApplicationReducer, reportConflictReducer, restartApplicationReducer, studentData, submitDetailsReducer, unassignApplicationReducer, overrideRecommendationReducer, createTrainingReducer, updatePriorityReducer, unassignTraineeReducer, toggleHoldForAdminReducer } from "./Applicant/reducers";
import { authentication } from "./Authentication/reducers";
import { DashboardReducer, InProgressReducer, NextAppReducer } from "./Dashboard/reducers";
import { ManageReaderReducer, updateManageReviewerReducer } from "./ManageReviewer/reducers";
import { manageReviewersReducer } from "./ManageReviewersBulkAssign/reducers";
import { manageActiveReviewersReducer } from "./ManageActiveReviewers/reducers";
import { navbarSearchReducer } from "./Navbar/reducers";
import { manageApplicationsMultiReducer } from "./ManageApplications/reducers";
import { manageApplicationsAssignedReducer } from "./AssignDrawer/reducers";
import { reportsExportsReducer } from "./ReportsExports/reducers";
import { manageAdmissionsCyclesReducer } from "./ManageAdmissionsCycles/reducers";
import { reportsReducer } from "./Common/components/reports/reducers";
import { reportsDashReducer } from "./ReportsDash/reducers";
import { ultimateSearchReducer } from "./AuditLookup/reducers";
import athenaReportReducer from "./ReportAthenaCustom/reducers";

export default combineReducers({
  authentication,
  studentData,
  manageActiveReviewersReducer,
  manageReviewersReducer,
  ManageReaderReducer,
  updateManageReviewerReducer,
  navbarSearchReducer,
  DashboardReducer,
  InProgressReducer,
  NextAppReducer,
  committeeReviewerReducer,
  assignApplicationReducer,
  unassignApplicationReducer,
  reassignApplicationReducer,
  reportConflictReducer,
  submitDetailsReducer,
  notifyAdminReducer,
  clearNotifyAdminReducer,
  restartApplicationReducer,
  manageApplicationsMultiReducer,
  manageApplicationsAssignedReducer,
  overrideRecommendationReducer,  
  createTrainingReducer,
  reportsExportsReducer,
  manageAdmissionsCyclesReducer,
  reportsReducer,
  reportsDashReducer,
  athenaReportReducer,
  updatePriorityReducer,
  toggleHoldForExportReducer,
  toggleHoldForAdminReducer,
  unassignTraineeReducer,
  ultimateSearchReducer,
});

