export const getApplication = `query GetApplication($applicantEmployeeId: String!, $admitTerm: String, $committeeId: String) {
  getApplication(applicantEmployeeId: $applicantEmployeeId, admitTerm: $admitTerm, committeeId: $committeeId) {
    status
    data {
      academicPlan {
        key
        value
      }
      academicProgram {
        key
        value
      }
      minimumRequiredConfiguration {
        foreignLanguage
        math
        naturalScience
      }
      administrativeRecommendationConfiguration {
        adminReasons
      }
      attemptedHoursPU
      earnedHoursPU
      applicantEmployeeId
      applicationDate
      adminAction
      admitType
      assignable
      buckeyeStudentLeadershipAcademy
      cacrScore
      campus
      classRankPercentile
      classRankType
      classSize
      classStanding
      county
      cumulativePUGPA
      decision
      decisionOverride
      dateAssigned
      dateOfBirth
      earlyAction
      evaluationDate
      firstGen
      firstName
      highSchoolName
      highSchoolGPA
      highSchoolLocation
      highSchoolGraduationDate
      homeCountry
      holdForExport
      holdForAdmin
      honors
      lastName
      lowSocioeconomic
      middleInitial
      msp
      onTime
      priority
      recruitmentCategory
      residency
      residencyCategory
      role
      rural
      scholars
      siblings
      stage
      status
      reason
      reviewNotesOptions {
        othersConcerns {
          order
          parentLabel
          label
        }
        noncompetitive {
          order
          parentLabel
          label
        }
        evaluation {
          order
          parentLabel
          label
        }
        document {
          order
          parentLabel
          label
        }
        competitive {
          order
          parentLabel
          label
        }
        notify {
          order
          parentLabel
          label
        }
        restrictions {
          order
          parentLabel
          label
        }
      }
      admitTerm {
        key
        value
      }
      bestTest {
        score
        optional
      }
      ccp {
        motivation
        maturityLevel
        academicAbility
      }
      committee {
        key
        value
        options {
          key
          value
          sort
          additionalOptions
        }
      }
      collegePrepUnits {
        earnedForeign
        earnedMath
        earnedScience
      }
      countryOfCitizenship
      documents {
        committeeId
        onBaseDocumentType
        onBaseId
        temporarySignedUrl
        term
        type
        uploadDate
      }
      highSchoolUnits {
        math
        naturalScience
        art
        english
        other
        socialScience
      }
      readers {
        applicantEmployeeId
        adminAction
        committeeId
        data
        createdDatetime
        firstName
        initialApprover
        lastName
        notificationCleared
        notificationCreated
        readerFirstName
        readerLastName
        readerEmployeeId
        recommendation
        restriction
        reviewNotes {
          othersConcerns {
            label
            order
            parentLabel
          }
          noncompetitive {
            label
            order
            parentLabel
          }
          evaluation {
            label
            order
            parentLabel
          }
          document {
            label
            order
            parentLabel
          }
          competitive {
            label
            order
            parentLabel
          }
          notify {
            label
            order
            parentLabel
          }
        }
        stageNumber
        status
        submissionDatetime
        term
      }
      recommendationHistory {
        reviewerEmployeeId
        stage
        assignTime
        submitTime
        firstName
        notificationCleared
        notificationCreated
        reason
        recommendation
        restriction
        lastName
        type
        comments {
          adminOverride
          competitive {
            label
          }
          noncompetitive {
            label
          }
          document {
            label
          }
          evaluation {
            label
          }
          othersConcerns {
            label
          }
          notify {
            label
          }

        }
      }
      scores {
        toefl {
          date
          listening
          reading
          speaking
          total
          writing
        }
        sat {
          date
          evidenceBased
          mathSection
          readingTest
          total
        }
        ielts {
          bandscore
          date
          listening
          reading
          speaking
          writing
        }
        duolingo {
          cmprh
          cnvrs
          date
          englishTest
          literacy
          productivity
        }
        act {
          composite
          date
          english
          math
          reading
          science
        }
        accuplacer {
          acuqu
          acure
          acuwr
          date
        }
        cacr
      }
      universityInfo {
        attemptedUnits
        city
        completedUnits
        country
        cumGPA
        fromDate
        name
        toDate
        state
      }
    }
    error {
      message
      date
    }
  }
}
`;

export const searchApplicationDocuments = `query SearchApplicationDocuments($applicantEmployeeId: String!, $admitTerm: String, $committeeId: String, $isTraining: Boolean) {
searchApplicationDocuments(applicantEmployeeId: $applicantEmployeeId, admitTerm: $admitTerm, committeeId: $committeeId, isTraining: $isTraining) {
  data {
    documentID
    documentName
    documentDate
    customQuery
    displayColumns
    documentType
    documentTypeGroup
    documentTypes
    endDate
    fromID
    keywords {
      keywordName
      keywordOperator
      keywordRelation
      keywordValue
    }
    noteType
    rawDocumentDataProvider
    returnRawDocumentData
    sorts {
      sortAscending
      sortField
      sortType
    }
    startDate
    textSearchConstraints
    toID
  }
  error {
    message
    date
  }
  status
}
}`

export const searchApplicationDocumentsReadOnlyQuery = `query SearchApplicationDocumentsReadOnly($applicantEmployeeId: String!, $committeeId: String!) {
  searchApplicationDocumentsReadOnly(applicantEmployeeId: $applicantEmployeeId, committeeId: $committeeId) {
    data {
      documentID
      documentName
      documentDate
      customQuery
      displayColumns
      documentType
      documentTypeGroup
      documentTypes
      endDate
      fromID
      keywords {
        keywordName
        keywordOperator
        keywordRelation
        keywordValue
      }
      noteType
      rawDocumentDataProvider
      returnRawDocumentData
      sorts {
        sortAscending
        sortField
        sortType
      }
      startDate
      textSearchConstraints
      toID
    }
    error {
      message
      date
    }
    status
  }
  }`

export const getCommitteeReviewers = `query GetCommitteeReviewersFromDB($committeeId: String!, $createdDate: String!, $includeInactive:Boolean) {
  getCommitteeReviewersFromDB(committeeId: $committeeId, createdDate: $createdDate, includeInactive: $includeInactive) {
    admin
    firstName
    lastName
    reviewerEmployeeId
    campusId
    experienceLabel
    jobType
  }
}`;

export const getApplicationTrainingDocument = `query GetApplicationTrainingDocument($documentId: String!, $documentName: String, $admitTerm: String!, $applicantEmployeeId: String!, $committeeId: String!) {
  getApplicationTrainingDocument(documentId: $documentId, documentName: $documentName, admitTerm: $admitTerm, applicantEmployeeId: $applicantEmployeeId, committeeId: $committeeId) {
    data {
      signedUrl
    }
    error {
      date
      message
    }
    status
  }
  }`;

export const getApplicationDocument = `query GetApplicationDocument($documentId: String!, $documentName: String, $admitTerm: String!, $applicantEmployeeId: String!, $committeeId: String!) {
getApplicationDocument(documentId: $documentId, documentName: $documentName, admitTerm: $admitTerm, applicantEmployeeId: $applicantEmployeeId, committeeId: $committeeId) {
  data {
    signedUrl
  }
  error {
    date
    message
  }
  status
}
}`;

export const getApplicationDocumentReadOnly = `query GetApplicationDocumentReadOnly($documentId: String!, $documentName: String, $applicantEmployeeId: String!) {
  getApplicationDocumentReadOnly(documentId: $documentId, documentName: $documentName, applicantEmployeeId: $applicantEmployeeId) {
    data {
      signedUrl
    }
    error {
      date
      message
    }
    status
  }
  }`;

export const getExpectedRecommendationQuery = `query GetExpectedRecommendation($applicantEmployeeId: String!, $committeeId: String!, $term: String!) {
  getExpectedRecommendation(applicantEmployeeId: $applicantEmployeeId, committeeId: $committeeId, term: $term) {
    status
    details
    error {
      message
      date
    }
  }
}`;

export const getTrainingConfigQuery = `query GetTrainingConfig($applicantEmployeeId: String!, $committeeId: String!, $term: String!) {
  getTrainingConfig(applicantEmployeeId: $applicantEmployeeId, committeeId: $committeeId, term: $term) {
    status
    trainingExists
    options {
        key
        sort
        value
    }
    error {
      message
      date
    }
  }
}`;

export const getTraining = `query GetTraining($applicantEmployeeId: String!, $admitTerm: String!, $committeeId: String!) {
  getTraining(applicantEmployeeId: $applicantEmployeeId, admitTerm: $admitTerm, committeeId: $committeeId) {
    status
    data {
      academicPlan {
        key
        value
      }
      academicProgram {
        key
        value
      }
      minimumRequiredConfiguration {
        foreignLanguage
        math
        naturalScience
      }
      administrativeRecommendationConfiguration {
        adminReasons
      }
      attemptedHoursPU
      earnedHoursPU
      applicantEmployeeId
      applicationDate
      admitType
      buckeyeStudentLeadershipAcademy
      cacrScore
      campus
      classRankPercentile
      classRankType
      classSize
      classStanding
      county
      cumulativePUGPA
      decision
      dateAssigned
      dateOfBirth
      earlyAction
      evaluationDate
      firstGen
      firstName
      highSchoolName
      highSchoolGPA
      highSchoolLocation
      highSchoolGraduationDate
      homeCountry
      honors
      lastName
      lowSocioeconomic
      middleInitial
      msp
      onTime
      recruitmentCategory
      residency
      residencyCategory
      role
      rural
      scholars
      siblings
      stage
      status
      reviewNotesOptions {
        othersConcerns {
          order
          parentLabel
          label
        }
        noncompetitive {
          order
          parentLabel
          label
        }
        evaluation {
          order
          parentLabel
          label
        }
        document {
          order
          parentLabel
          label
        }
        competitive {
          order
          parentLabel
          label
        }
        restrictions {
          order
          parentLabel
          label
        }
      }
      admitTerm {
        key
        value
      }
      bestTest {
        score
        optional
      }
      ccp {
        motivation
        maturityLevel
        academicAbility
      }
      committee {
        key
        value
        options {
          key
          value
          sort
        }
      }
      collegePrepUnits {
        earnedForeign
        earnedMath
        earnedScience
      }
      countryOfCitizenship
      documents {
        committeeId
        onBaseDocumentType
        onBaseId
        temporarySignedUrl
        term
        type
        uploadDate
      }
      highSchoolUnits {
        math
        naturalScience
        art
        english
        other
        socialScience
      }
      readers {
        applicantEmployeeId
        adminAction
        committeeId
        data
        createdDatetime
        firstName
        initialApprover
        lastName
        readerFirstName
        readerLastName
        readerEmployeeId
        recommendation
        restriction
        reviewNotes {
          othersConcerns {
            label
            order
            parentLabel
          }
          noncompetitive {
            label
            order
            parentLabel
          }
          evaluation {
            label
            order
            parentLabel
          }
          document {
            label
            order
            parentLabel
          }
          competitive {
            label
            order
            parentLabel
          }
        }
        stageNumber
        status
        submissionDatetime
        term
      }
      scores {
        toefl {
          date
          listening
          reading
          speaking
          total
          writing
        }
        sat {
          date
          evidenceBased
          mathSection
          readingTest
          total
        }
        ielts {
          bandscore
          date
          listening
          reading
          speaking
          writing
        }
        duolingo {
          cmprh
          cnvrs
          date
          englishTest
          literacy
          productivity
        }
        act {
          composite
          date
          english
          math
          reading
          science
        }
        accuplacer {
          acuqu
          acure
          acuwr
          date
        }
        cacr
      }
      universityInfo {
        attemptedUnits
        city
        completedUnits
        country
        cumGPA
        fromDate
        name
        toDate
        state
      }
    }
    error {
      message
      date
    }
  }
}
`;

export const getApplicationReadOnlyQuery = `query GetApplicationReadOnly($applicantEmployeeId: String!, $admitTerm: String!, $committeeId: String!) {
  getApplicationReadOnly(applicantEmployeeId: $applicantEmployeeId, admitTerm: $admitTerm, committeeId: $committeeId) {
    status
    data {
      academicPlan {
        key
        value
      }
      academicProgram {
        key
        value
      }
      minimumRequiredConfiguration {
        foreignLanguage
        math
        naturalScience
      }
      administrativeRecommendationConfiguration {
        adminReasons
      }
      earnedHoursPU
      applicantEmployeeId
      applicationDate
      admitType
      assignable
      buckeyeStudentLeadershipAcademy
      cacrScore
      campus
      classRankPercentile
      classRankType
      classSize
      classStanding
      county
      cumulativePUGPA
      decision
      dateAssigned
      dateOfBirth
      earlyAction
      evaluationDate
      firstGen
      firstName
      highSchoolName
      highSchoolGPA
      highSchoolLocation
      highSchoolGraduationDate
      homeCountry
      honors
      lastName
      lowSocioeconomic
      middleInitial
      msp
      onTime
      recruitmentCategory
      residency
      residencyCategory
      role
      rural
      scholars
      siblings
      stage
      status
      reason
      reviewNotesOptions {
        othersConcerns {
          order
          parentLabel
          label
        }
        noncompetitive {
          order
          parentLabel
          label
        }
        evaluation {
          order
          parentLabel
          label
        }
        document {
          order
          parentLabel
          label
        }
        competitive {
          order
          parentLabel
          label
        }
        restrictions {
          order
          parentLabel
          label
        }
      }
      admitTerm {
        key
        value
      }
      bestTest {
        score
        optional
      }
      ccp {
        motivation
        maturityLevel
        academicAbility
      }
      committee {
        key
        value
        options {
          key
          value
          sort
        }
      }
      collegePrepUnits {
        earnedForeign
        earnedMath
        earnedScience
      }
      countryOfCitizenship
      documents {
        committeeId
        onBaseDocumentType
        onBaseId
        temporarySignedUrl
        term
        type
        uploadDate
      }
      highSchoolUnits {
        math
        naturalScience
        art
        english
        other
        socialScience
      }
     

      recommendationHistory {
        reviewerEmployeeId
        stage
        assignTime
        submitTime
        firstName
        notificationCleared
        notificationCreated
        reason
        recommendation
        restriction
        lastName
        type
        comments {
          adminOverride
          competitive {
            label
          }
          noncompetitive {
            label
          }
          document {
            label
          }
          evaluation {
            label
          }
          othersConcerns {
            label
          }
          notify {
            label
          }

        }
      }      
      scores {
        toefl {
          date
          listening
          reading
          speaking
          total
          writing
        }
        sat {
          date
          evidenceBased
          mathSection
          readingTest
          total
        }
        ielts {
          bandscore
          date
          listening
          reading
          speaking
          writing
        }
        duolingo {
          cmprh
          cnvrs
          date
          englishTest
          literacy
          productivity
        }
        act {
          composite
          date
          english
          math
          reading
          science
        }
        accuplacer {
          acuqu
          acure
          acuwr
          date
        }
        cacr
      }
      universityInfo {
        attemptedUnits
        city
        completedUnits
        country
        cumGPA
        fromDate
        name
        toDate
        state
      }
    }
    error {
      message
      date
    }
  }
}
`;