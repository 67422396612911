import React from "react";
import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => ({
  root: {
    display: "flex",
  }
}));

const NoncompetitiveQualities = (props) => {
  const classes = useStyles();
  const {
    studentData,
    renderInputControls
  } = props; 

  return (
    <div className={classes.root}>
      <Mui.FormControl component="fieldset">
        <Mui.FormLabel component="legend">
          {studentData?.data?.notes?.noncompetitive && <Mui.Typography variant="h6" component="h3">
            Non-Competitive Application Attributes
          </Mui.Typography>}
        </Mui.FormLabel>
        <Mui.FormGroup>
        {renderInputControls(studentData?.data?.notes?.noncompetitive, "noncompetitive")}
        </Mui.FormGroup>
      </Mui.FormControl>
    </div>
  );
};

export default NoncompetitiveQualities;
