import { Mui } from '@osu/react-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAlertHandling } from '../../Common/hooks/useAlertHandling';
import { getOverrideRecommendationState, getStudentState, getSubmitRecommendationState } from '../../Common/redux/selectors';
import { OVERRIDE_RECOMMENDATION } from '../../actions-index';
import { ERROR_CONSTS, STATUS, RECOMMENDATIONS, NOTES_KEYS } from '../../util/constants';
import { getAdditionalOptionsMap } from '../../util/additionalOptions';

const Submit = (props) => {
  const dispatch = useDispatch()
  const studentData = useSelector((state) => getStudentState(state)).data;
  const submitState = useSelector((state) => getSubmitRecommendationState(state));
  const overrideState = useSelector((state) => getOverrideRecommendationState(state));
  const alert = useAlertHandling({ alert: submitState?.alert });
  const { open, decision, name, committee, isOverrideDecision, noteState } = props; // State
  const { handleCloseBackDrop, handleSubmit, noteDispatch } = props; // Fn calls
  
  const isDisabledDueToMissingRestrictionNote = isRestrictionsNoteMissing(decision, noteState);
  const additionalOptions = studentData?.committeeDetails?.committeeOptions?.find((item) => item.additionalOptions)

    useEffect(() => {
      if (overrideState?.status === STATUS.SUCCESS) {
        handleCloseBackDrop()
        dispatch({
          type: OVERRIDE_RECOMMENDATION,
          payload: {
            status: 'SUCCESS',
            alert: ERROR_CONSTS?.UI?.GENERIC?.EMPTY_ERROR_OBJECT,
            details: '',
          },
        });
      }
    }, [overrideState?.status]);

  return (
    <Mui.Dialog
      open={open}
      aria-live='polite'
      onClose={handleCloseBackDrop}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      {alert}
      <Mui.DialogTitle tabindex='0' id="alert-dialog-title">{isOverrideDecision ? 'Administrative' : 'Submit'} Recommendation</Mui.DialogTitle>
      <Mui.DialogContent>
        <Mui.DialogContentText tabindex='0' id="alert-dialog-description" >
          You are about to {isOverrideDecision ? 'submit an administrative recommendation for this review with a recommendation of' : 'recommend'} {decision} for {name} of the {committee} Committee.
        </Mui.DialogContentText>
        { additionalOptions?.additionalOptions && additionalOptions?.value === decision && 
          getAdditionalOptionsMap({ type: additionalOptions?.additionalOptions, payload: { noteDispatch: noteDispatch }}) }
      </Mui.DialogContent>
      <Mui.DialogActions className="padding-2">
        <Mui.Button
          style={{ marginRight: '1rem' }}
          variant="outlined"
          size="small"
          onClick={handleCloseBackDrop}
        >
          Cancel
        </Mui.Button>
        <Mui.Button
          onClick={() => handleSubmit()}
          variant="contained"
          color="primary"
          size="small"
          disabled={submitState?.status === STATUS.LOADING || overrideState?.status === STATUS.LOADING || isDisabledDueToMissingRestrictionNote}
          autoFocus
        >
          {submitState?.status === STATUS.LOADING || overrideState?.status === STATUS.LOADING
            ? <><Mui.CircularProgress size={20} style={{ marginRight: '.5rem' }} />{'Submitting'}</>
            : 'Submit'}
        </Mui.Button>
      </Mui.DialogActions>
    </Mui.Dialog>
  );
};

function isRestrictionsNoteMissing(decision, noteState) {
    if (decision !== RECOMMENDATIONS.ADMIT_WITH_RESTRICTION) { return false; } // Skip check, only applies to 1 specific recommendation option

    const restrictionsNotesKeys = Object.keys(noteState).filter(key => noteState[key]?.section === NOTES_KEYS.RESTRICTIONS);
    const restrictionsNotes = restrictionsNotesKeys.map(key => noteState[key]);
    const hasACheckedRestriction = restrictionsNotes.some(restriction => !restriction.hasChildren && restriction.value === true);

    return !hasACheckedRestriction; // Must have at least 1 restriction checkbox checked
}

export default Submit;
