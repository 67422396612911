import React from "react";
import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => {
  return {
    width100: {
      width: "100%",
    },
    width50: {
      width: "50%",
    },
    listItemText: {
      flexBasis: "50%",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(2),
    },
    listItemLast: {
      flexBasis: "50%",
      display: "flex",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
    dataList: {
      display: "flex",
      flexWrap: "wrap",
    },
  };
});

const PreviousUniversity = (props) => {
  const classes = useStyles();
  const { studentData } = props;

  function getSafeDate(date) {
    if (!date) { return "-"; }
    return (new Date(date)).toLocaleString().split(",")[0];
  }

  function getFromToDates(fromDate, toDate) {
    fromDate = getSafeDate(fromDate);
    toDate = getSafeDate(toDate);
    toDate = toDate?.trim()?.length && toDate !== "-" ? `- ${toDate}` : "";
    return `${fromDate} ${toDate}`;
  }

  const schools = studentData?.data?.universityInfo?.schools ?? [];
  const widthClass = schools.length === 1 ? classes.width100 : classes.width50;
 
  return schools.length > 0 ? (
    <React.Fragment>
      <Mui.Box className={classes.width100}>
        <Mui.Typography style={{marginBottom: "1rem"}}>
          Cumulative GPA: {studentData?.data?.universityInfo?.cumGPA} <br/>
          Overall Attempted Hours: {studentData?.data?.attemptedHoursPU} <br/>
          Overall Earned Hours: {studentData?.data?.earnedHoursPU}
        </Mui.Typography>
        <Mui.Box className={classes.dataList}>
          {schools.map(
            ({ name, city, state, completedUnits, attemptedUnits, cumGPA, fromDate, toDate }, index) => {
              return (
                <Mui.Box className={widthClass} key={`${name}-${city}-${state}`}>
                  <Mui.List>
                  <Mui.ListItemText
                    key={`name-${index}`}
                    primary={name}
                    secondary={`${city} ${state}`}
                    className={classes.listItemText}
                    primaryTypographyProps={{
                      component: "dt",
                      className: "margin-right-2",
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{
                      component: "dd",
                      variant: "body2",
                      color: "textPrimary",
                    }}
                  />
                  <Mui.ListItemText
                    key={`dates-${index}`}
                    primary={"Dates"}
                    secondary={`${getFromToDates(fromDate, toDate)}`}
                    className={classes.listItemText}
                    primaryTypographyProps={{
                      component: "dt",
                      className: "margin-right-2",
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{
                      component: "dd",
                      variant: "body2",
                      color: "textPrimary",
                    }}
                  />
                  <Mui.ListItemText
                    key={`cumgpa-${index}`}
                    primary={"Cumulative GPA"}
                    secondary={`${cumGPA ?? "-"}`}
                    className={classes.listItemText}
                    primaryTypographyProps={{
                      component: "dt",
                      className: "margin-right-2",
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{
                      component: "dd",
                      variant: "body2",
                      color: "textPrimary",
                    }}
                  />
                  <Mui.ListItemText
                    key={`completedUnits-${index}`}
                    primary={"Completed Units"}
                    secondary={completedUnits}
                    className={classes.listItemText}
                    primaryTypographyProps={{
                      component: "dt",
                      className: "margin-right-2",
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{
                      component: "dd",
                      variant: "body2",
                      color: "textPrimary",
                    }}
                  />
                  <Mui.ListItemText
                    key={`attemptedUnits-${index}`}
                    primary={"Attempted Units"}
                    secondary={attemptedUnits}
                    className={classes.listItemLast}
                    primaryTypographyProps={{
                      component: "dt",
                      className: "margin-right-2",
                      variant: "subtitle2",
                    }}
                    secondaryTypographyProps={{
                      component: "dd",
                      variant: "body2",
                      color: "textPrimary",
                    }}
                  />
                  </Mui.List>
                </Mui.Box>
              );
            }
          )}
        </Mui.Box>
      </Mui.Box>
    </React.Fragment>
  ) : (
    <></>
  );
};

export default PreviousUniversity;
