import { Mui } from '@osu/react-ui';
import Chip from './scoresChip';
import useStyles from '../../dd-styles';

const ACTScores = (props) => {
  const { studentData } = props;
  const classes = useStyles();

  return (
    <>
      <Mui.Typography aria-label='A C T' tabindex="0" component="h3" variant="h6">
        ACT
      </Mui.Typography>
      {studentData?.data?.scores?.act?.length > 0 ? (
        <Mui.Box style={{ display: 'flex', flexWrap: 'wrap' }}>
          {studentData?.data?.scores?.act?.map?.((item, index) => (
            <dl title="A C T" key={index} className={classes.dl}>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Date'}
                  childLabel={item.map((item) => item.date)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Composite'}
                  childLabel={item.map((item) => item.composite)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Math'}
                  childLabel={item.map((item) => item.math)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Reading'}
                  childLabel={item.map((item) => item.reading)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'English'}
                  childLabel={item.map((item) => item.english)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Science'}
                  childLabel={item.map((item) => item.science)}
                />
              </dd>
              <Mui.Divider />
            </dl>
          ))}
        </Mui.Box>
      ) : (
        <></>
      )}
    </>
  );
};
export default ACTScores;
