import { Mui } from '@osu/react-ui';
import { useState } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import useRoleAuthentication from '../../../Common/hooks/useRoleAuthentication';
import { TOOLTIPS } from "../../../util/constants";
import { handleKeyPresses } from "./rechist-treegrid-accessibility";

const useStyles = Mui.makeStyles(() => {
  return {
    screenReaderOnly: {
        left: "-9999px",
        position: "absolute !important",
    },
    removeLastChildBottomBorder: {
      "& td:first-child": { textAlign: "center" },
      "&:nth-last-child(1 of .hasBottomLine) td": { borderBottom: "none" },
      "& td": {
        paddingTop: "0.2rem",
        paddingBottom: "0.2rem",
      },
    },
    thrReview: {
        textAlign: "center",
        width: "5rem",
    },
    thrTime: { width: "13rem", },
    verticallyCenter: {
        display: "flex",
        alignIems: "center",
    },
    clickToExpandRow: { cursor: "pointer" },
    expandCollapse: { width: "52px", },
    expandableRow: {
        "& td": {
            borderBottom: "none",
            padding: "0",
        }
    },
    expandedRowDataWrapper: {   // Necessary to keep a consistent background color and sizing for 2nd row
        backgroundColor: '#f2f2f2',
        padding: "16px",
        "& span": {
            paddingTop: "4px",
            paddingBottom: "4px",
        }    
    },
    expandableBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    section: {
        display: "flex",
        flexDirection: 'column',
        width: '25%',
    },
    indent: { paddingLeft: "2rem" },
    invisibleButton: { visibility: "hidden" }, // Necessary for proper spacing
  };
});

const HistoryTable = (props) => {
  const classes = useStyles();
  const { recommendationHistory } = props?.studentData?.data ?? {};
  // console.log("recommendationHistory:", recommendationHistory)
  
  return recommendationHistory.length === 0 ? (
    'No recommendations have been submitted for this application.'
  ) : (
    <Mui.Table aria-label="Recommendation History" role="treegrid">
        <Mui.TableHead>
            <Mui.TableRow>
                <Mui.TableCell className={classes.thrReview}>Review</Mui.TableCell>
                <Mui.TableCell className={classes.thrTime}>Assign Time</Mui.TableCell>
                <Mui.TableCell className={classes.thrTime}>Submit Time</Mui.TableCell>
                <Mui.TableCell>Name</Mui.TableCell>
                <Mui.TableCell>Recommendation</Mui.TableCell>
                <Mui.TableCell aria-label="Button to expand/collapse row" className={classes.expandCollapse}></Mui.TableCell>
            </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
            {
                recommendationHistory.map((row, index, allRows) => {
                    return ( <HistoryReviewerDoubleRow key={index} row={row} index={index} allRows={allRows} /> );
                })
            }
        </Mui.TableBody>
    </Mui.Table>
  );
};

function HistoryReviewerDoubleRow({row, index, allRows}) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const authDetails = useRoleAuthentication();
    const canExpand = authDetails.ADMIN;

    function flipExpanded(event) { setExpanded(canExpand && !this); event.preventDefault(); event.stopPropagation(); }
    function getExpandedButton(expanded) { return expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
    
    const parentRowClass = [classes.removeLastChildBottomBorder, "hasBottomLine", canExpand ? classes.clickToExpandRow : ""].join(" ");
    const buttonClasses = [classes.arrow, canExpand ? "" : classes.invisibleButton].join(" ");
    const keyPressHandler = handleKeyPresses.bind({ expanded, setExpanded });
    const colSpanCount = 5 + (canExpand ? 1 : 0);
    const showNotificationIcon = row.notificationCreated && (!row.notificationCleared);
    
    return (
        <>
            {/* Main row of reviewer recommendation details */}
            <Mui.TableRow data-rowhasfocusables={"true"} onKeyDown={keyPressHandler} className={parentRowClass} onClick={flipExpanded.bind(expanded)} role="row" aria-level="1" aria-posinset={index} aria-setsize={allRows.length}>
                <Mui.TableCell role="gridcell" aria-label={`${expanded ? "Expanded" : "Collapsed"} ${row.stage}`} tabindex="0">{row.stage}</Mui.TableCell>
                <Mui.TableCell role="gridcell" tabindex="0">{row.assignTimeFormatted}</Mui.TableCell>
                <Mui.TableCell role="gridcell" tabindex="0">{row.submitTimeFormatted}</Mui.TableCell>
                <Mui.TableCell role="gridcell" tabindex="0"><div className={classes.verticallyCenter}>{row.nameFormatted}{showNotificationIcon && ( TOOLTIPS('NOTIFY') )}</div></Mui.TableCell>
                <Mui.TableCell role="gridcell" tabindex="0">{row.recommendationUnified}</Mui.TableCell>
                <Mui.TableCell role="gridcell">
                    <Mui.IconButton className={buttonClasses} onClick={flipExpanded.bind(expanded)} aria-label={expanded ? "Row Expanded" : "Row Collapsed"} size="small" tabindex="0">
                        { getExpandedButton(expanded) }
                    </Mui.IconButton>
                </Mui.TableCell>
            </Mui.TableRow>
            
            {/* Expandable row of reviewer notes/details for admins */}
            { canExpand && (
                <Mui.TableRow data-rowhasfocusables={expanded} onKeyDown={keyPressHandler} className={classes.expandableRow} role="row" aria-expanded={expanded.toString()} aria-level="2" aria-posinset="1" aria-setsize="1" tabindex="-1">
                    <Mui.TableCell aria-label="Review Notes" colSpan={colSpanCount} role="gridcell" tabindex="-1">
                        <Mui.Collapse in={expanded} timeout="auto" unmountOnExit>
                            <div className={classes.expandedRowDataWrapper}>
                                <HistoryRow2ExpandableBox expanded={expanded} row={row} />
                            </div>
                        </Mui.Collapse>
                    </Mui.TableCell>
                </Mui.TableRow>
            )}
        </>
    );
}

function HistoryRow2ExpandableBox({ expanded, row }) {
    const classes = useStyles();
    const isAppDecisionOverride = row.type === "ApplicationDecisionOverride" && row.adminOverrideComment?.length > 0;
    const commentsExist = isAppDecisionOverride || row.comments?.some?.(section => section.hasNotes);

    if (!commentsExist       ) { return <span data-focusable={expanded} tabindex="0">There are no associated notes with this review.</span>; }
    if (isAppDecisionOverride) { return <span data-focusable={expanded} tabindex="0"><b>Administrative Recommendation Comments:</b> {row.adminOverrideComment}</span>; }
    console.info("typ", row, row.adminOverrideComment);
        
    return (
        <>
            <Mui.Typography className={classes.screenReaderOnly} component="h3">Notes from {row.nameFormatted}</Mui.Typography>
            <Mui.Box className={classes.expandableBox}>
                { row.comments.map((section, index) => ( <HistoryRowNotes key={index} expanded={expanded} index={index} section={section} /> )) }
            </Mui.Box>
        </>
    );
}

function HistoryRowNotes({ expanded, index, section }) {
    const classes = useStyles();
    
    return (
        <Mui.Box aria-label={`${section.title} Section`} className={classes.section} data-focusable={expanded} tabindex="0">
            <Mui.Typography component="h4" variant="subtitle2">{section.title}</Mui.Typography>
            { section.notes?.map((note, index) => ( <span className={note.shouldIndent && classes.indent} key={index}>{note.label}</span> )) }
        </Mui.Box>
    );
}

export default HistoryTable;