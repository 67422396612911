import { useState } from "react";
import { Mui, MuiLab } from '@osu/react-ui';

let timer = undefined;
let isRunning = false;


function RefreshPageAlert() {
    const styles = { alignItems: "center" };
    const currentLoadedFilename = Array.from(document.getElementsByTagName("script")).map(scriptTag => scriptTag?.attributes?.["src"]?.value ?? "").find(scriptSource => scriptSource.includes("/static/js/main"));
    const [versionsMatch, setVersionsMatch] = useState(true);
    const [closedForcedRefreshMessage, setClosed] = useState(false);
        
    javascriptVersionChecker(currentLoadedFilename, setVersionsMatch);
    const wasForcedRefresh = window.location.search.includes("reloaded=true") && !closedForcedRefreshMessage;
    const showSnackbarVersionMismatch = !versionsMatch;
    const showSnackbarValidationError = wasForcedRefresh && !closedForcedRefreshMessage;
    
    const action = (
        <div className="display-flex justify-content-space-evenly align-items-center">
            <Mui.Link href="javascript:window.location.reload()" className="margin-right-2">Refresh Page</Mui.Link>
        </div>
    );
    
    return (
        <>
            <Mui.Snackbar open={showSnackbarValidationError} TransitionComponent={Mui.Slide} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <MuiLab.Alert onClose={() => setClosed(true)} severity="warning" style={styles}>Website changes detected, page has been reloaded</MuiLab.Alert>
            </Mui.Snackbar>
            
            <Mui.Snackbar open={showSnackbarVersionMismatch} TransitionComponent={Mui.Slide} anchorOrigin={{ vertical: 'top', horizontal: 'center', }}>
                <MuiLab.Alert action={action} severity="warning" style={styles}>Website changes detected</MuiLab.Alert>
            </Mui.Snackbar>
        </>
    );
}

function javascriptVersionChecker(currentLoadedFilename, setVersionsMatch) {
    const seconds = 60 * 10; // 10 minutes
    const intervalMs = seconds * 1000;
    
    clearInterval(timer);
    timer = setInterval(() => checkJavascriptVersion(currentLoadedFilename, setVersionsMatch), intervalMs);
}

async function checkJavascriptVersion(currentLoadedFilename, setVersionsMatch) {
    const regex = new RegExp(/\/static\/js\/main[.]{0,1}[A-z0-9]{0,10}.chunk.js/, "dg");
    if (isRunning) { return; } // Prevent simultaneous runs
    
    isRunning = true;
    
    try {
        let latestHtml = "";
        
        latestHtml = await (await fetch("/")).text();
        
        const match1 = regex.exec(latestHtml)?.indices?.[0] ?? [0, 0]; // Get indices of bound match        
        const newestVersion = latestHtml.slice(...match1);
        
        // const javascriptFilenamesMatch = latestHtml.includes(currentLoadedFilename); // Basic check
        // console.info("Version metadata, current=", currentLoadedFilename, "newestVersion=", newestVersion, javascriptFilenamesMatch);
        const javascriptFilenamesMatch = newestVersion === currentLoadedFilename;
        if (javascriptFilenamesMatch) { isRunning = false; return; } // Still using same javascript file? Versions probably match.
        
        console.warn(`New main version detected (current=${currentLoadedFilename} new=${newestVersion}), prompting for refresh`);
        setVersionsMatch(false);
        clearInterval(timer);
    } catch (exception) {
        console.error("Error while attempting to fetch homepage for javascript version check");
    }
    
    isRunning = false;
}

export default RefreshPageAlert;