import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import MuiAlert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import { Mui } from '@osu/react-ui';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { STATUSES, TOOLTIPS } from '../../util/constants';
import { filterAndSortInProgress } from "../transform";
import { PullCard } from './pull-card';
import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";

const useStyles = Mui.makeStyles((theme) => {
  return {
    table: {
      minWidth: '100%',
    },
    Grid: {
      margin: '0 0 2rem 0',
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1rem',
      // justifyContent: 'center',
      // alignItems: 'center',
    },
    fixTopHat: {
      '&::before': {
        display: 'none',
      },
    },
    box: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  };
});

export const Dashboard = (props) => {
  const authDetails = useRoleAuthentication();
  
  const classes = useStyles();
  const {
    user,
    committeesData,
    committeeLoading,
    progressData,
    dashInprogress,
    progressLoading,
    nextAppData,
    cleanup,
    assignedData,
    pastDueData,
    trainingData
  } = props;
  const { getCommitteeList, getInProgress, updateNextApp } = props; // GraphQL calls
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
  let history = useHistory();
  const [committeeList, setCommitteeList] = useState([]);
  const [open, setOpen] = useState(false);
  const [nextAppError, setNextAppError] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [inProgressViewFilter, setInProgressViewFilter] = useState(["saved", "assigned"]);
  const [trainingViewFilter, setTrainingViewFilter] = useState(["assigned"]);
  const [inProgressData, setInProgressData] = useState([]);
  const [trainingLocalData, setTrainingLocalData] = useState([]);

  // snackbar controls
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  useEffect(() => {
    // clear alert on location change
    const unlisten = history.listen(() => {
      //call clear redux
      cleanup();
    });

    // stop the listener when component unmounts
    return unlisten;
  }, []);

  useEffect(() => {
    const filteredData = filterAndSortInProgress(inProgressViewFilter, progressData?.saved, progressData?.assigned, progressData?.notifyAdmin );
    setInProgressData(filteredData);
  }, [progressData, inProgressViewFilter]);
  
  useEffect(() => {
    if(!trainingData || trainingData.length === 0) return;
    let filtered = [];
    if(trainingViewFilter.length > 0) {
       if(trainingViewFilter.includes("assigned")) {
        filtered = [...filtered, ...trainingData?.filter(e => e.status === "Assigned")];
      } if(trainingViewFilter.includes("complete")) {
        filtered = [...filtered, ...trainingData?.filter(e => e.status === "Complete")];
      } else {
        setTrainingLocalData(trainingData);
      }
      setTrainingLocalData(filtered);
    } else {
      setTrainingLocalData(trainingData);
    }
  }, [trainingData, trainingViewFilter]);

  useEffect(() => {
    if(nextAppData?.nextApp?.status === "success") {
        history.push(`/applicant/${nextAppData?.nextApp?.applicantEmployeeId}/${nextAppData?.nextApp?.committeeId}/${nextAppData?.nextApp?.term}`);   
    }
    cleanup();
    setDisableButton(false);  
  }, [history, nextAppData?.nextApp?.applicantEmployeeId, nextAppData?.nextApp?.committeeId, nextAppData?.nextApp?.status, nextAppData?.nextApp?.term, cleanup]);

  useEffect(() => {
    if(authDetails.READ_ACCESS === true){
      getCommitteeList();
      getInProgress();
    }
  }, [getCommitteeList, getInProgress, authDetails.READ_ACCESS]);

  useEffect(() => {
    setCommitteeList(committeesData);
  }, [committeesData]);

//controls error messages if get next app fails
  useEffect(() => {
    if(nextAppData?.nextApp?.status === "failed") {
    setNextAppError(nextAppData?.nextApp?.error?.message ?? "There was an error retrieving the next application" );
    setOpen(true);
    setDisableButton(false); 
    } 
  }, [nextAppData?.nextApp?.error?.message, nextAppData?.nextApp?.status]);

  if (nextAppError) {
    console.log(nextAppError);
  }


 
  
  return (
    <Mui.Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Mui.Box className={classes.Grid}>
        {committeeLoading ? (
          <Mui.Box style={{display:'flex', justifyContents: 'flex-evenly', height: '10rem', width: '100%'}}>
            <Skeleton variant="rect" height='100%' width='100%'/>
          </Mui.Box>
        ) : (
          <>
          <Mui.Grid container className={classes.root} styles={{flexGrow: '1'}} spacing={2}>
            {committeeList?.map((committee) => 
              (
              <Mui.Grid item xs={12} sm={6} md={6} lg={4}>
                <PullCard
                  key={committee?.committeeName}
                  committee={committee}
                  inProgressLength={progressData?.saved?.filter(e => e.manuallyAssigned !== true || e.adminAction !== "NOTIFY")?.length ?? 0}
                  updateNextApp={updateNextApp}
                  disableButton={disableButton}
                  setDisableButton={setDisableButton}
                  reviewCountNew={assignedData?.find(e => e.committeeId === committee.committeeId)?.count ?? 0}
                  reviewCountInProgress={dashInprogress?.filter(e => e.committeeId === committee.committeeId)?.length ?? 0}
                  cardLink="#"
                  completed={assignedData?.find(e => e.committeeId === committee.committeeId)?.count === 0 ?? false}
                  pastDue={pastDueData?.find(e => e.committeeId === committee.committeeId)?.count ?? 0}
                  groups={user?.cognitoGroups}
                />
              </Mui.Grid>
            ))}
            </Mui.Grid>
          </>
        )} 
      <Mui.Snackbar open={open} onClose={handleClose} anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert onClose={handleClose} severity="info">
          {nextAppError ?? "There was an error retrieving the next application."}    
        </Alert>
      </Mui.Snackbar>
      </Mui.Box>

      <Mui.Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Mui.Accordion defaultExpanded={true}>
          <Mui.AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={'1'}
            heading={'test'}
            style={{ fontSize: '1rem' }}
            className={classes.accordion}
          >
            <Mui.FormControlLabel
              aria-label="In Progress"
              control={<></>}
              label="In Progress"
              style={{ paddingLeft: '1rem' }}
            />
          </Mui.AccordionSummary>
          <Mui.AccordionDetails>
          <Mui.Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
          >
          <ToggleButtonGroup
            value={inProgressViewFilter}
            onChange={(e, ev) => { setInProgressViewFilter(ev)}}
            aria-label="In progress view filter buttons"
          >
            <ToggleButton value="assigned" aria-label="assigned" style={{ margin: '1rem 0' }}>
                Manually Assigned ({progressData?.assigned?.length ?? 0})
              </ToggleButton>
              <ToggleButton value="saved" aria-label="saved for later" style={{ margin: '1rem 0' }}>
                Saved for later ({progressData?.saved?.length ?? 0})
              </ToggleButton>
              <ToggleButton value="admin" aria-label="admin" style={{ margin: '1rem 0' }}>
                Notified Admin ({progressData?.notifyAdmin?.length ?? 0})
              </ToggleButton>
            </ToggleButtonGroup>
            <br />
            <Mui.TableContainer>
              <Mui.Table
                className={classes?.table}
                aria-label="in progress list"
              >
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell>OSUID</Mui.TableCell>
                    <Mui.TableCell>Last Name</Mui.TableCell>
                    <Mui.TableCell>First Name</Mui.TableCell>
                    <Mui.TableCell>Committee</Mui.TableCell>
                    <Mui.TableCell>Assigned Date</Mui.TableCell>
                    <Mui.TableCell>Due Date</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {progressLoading ? (
                    <Mui.TableRow>
                      <Mui.TableCell colSpan={6} component="th" scope="row">
                        <Skeleton variant="rect" width="100%" height="2rem" />
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ) : (
                    inProgressData?.map((row) => (
                      //console.log("row", row ?? "no row")  ||
                      <Mui.TableRow
                        key={`${row.applicantEmployeeId}-${row.committeeId}-${row.term}`}
                      >
                        <Mui.TableCell component="th" scope="row">
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Mui.Link
                              component={Link}
                              to={`/applicant/${row.applicantEmployeeId}/${row.committeeId}/${row.term}`}
                            > {row.applicantEmployeeId} </Mui.Link>
                              { row?.adminAction === STATUSES.NOTIFY &&
                                TOOLTIPS('NOTIFY')
                              }
                              {row?.status === STATUSES.RESUMELATER && row?.adminAction !== STATUSES.NOTIFY &&
                                TOOLTIPS('RESUMELATER')}
                              { new Date(row?.completedByDueDate) < new Date() && row?.adminAction !== STATUSES.NOTIFY &&
                                TOOLTIPS('PASTDUE')
                              }
                              {row?.manuallyAssigned &&
                                TOOLTIPS('ASSIGNED')
                              }
                              {row?.notificationCleared &&
                                TOOLTIPS('NOTIFY_CLEARED')
                              }
                              {row?.training && 
                                TOOLTIPS('TRAINING')
                              }
                            </div>
                        </Mui.TableCell>
                        <Mui.TableCell>{row?.lastName}</Mui.TableCell>
                        <Mui.TableCell>{row?.firstName}</Mui.TableCell>
                        <Mui.TableCell>
                          {row?.committeeName}
                        </Mui.TableCell>
                        <Mui.TableCell>{ new Date(row?.createdDatetime).toLocaleDateString() }</Mui.TableCell>
                        <Mui.TableCell>{ new Date(row?.completedByDueDate).toLocaleDateString() } </Mui.TableCell>
                      </Mui.TableRow>
                    ))
                  )}
                </Mui.TableBody>
              </Mui.Table>
              {!progressLoading && inProgressData?.length <= 0 && (
                <Mui.Box className={classes.box}>
                  <Mui.Typography>
                    There are no applications to display.
                  </Mui.Typography>
                </Mui.Box>
              )}
            </Mui.TableContainer>
          </Mui.Box>
          </Mui.AccordionDetails>
        </Mui.Accordion>
      </Mui.Box>

      <Mui.Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          marginTop: '2rem'
        }}
      >
        <Mui.Accordion defaultExpanded={true}>
          <Mui.AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            style={{ fontSize: '1rem' }}
            // sortPriority={sortPriority}
            // AnchorHeadingProps={{
            //   className: `padding-y-2 ${classes.fixTopHat}`,
            // }}
            // key={`${index}${id}-jumpsection`}
            // component={TOC.Section}
            className={classes.accordion}
          >
            <Mui.FormControlLabel
              aria-label="Training Items"
              // onClick={(event) => event.stopPropagation()}
              // onFocus={(event) => event.stopPropagation()}
              control={<></>}
              label="Training Items"
              style={{ paddingLeft: '1rem' }}
            />
          </Mui.AccordionSummary>
          <Mui.AccordionDetails>
          <Mui.Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1
            }}
          >
          <ToggleButtonGroup
            value={trainingViewFilter}
            onChange={(e, ev) => { setTrainingViewFilter(ev)}}
            aria-label="training view filter buttons"
          >
              <ToggleButton value="assigned" aria-label="Assigned">
                Assigned ({trainingData?.filter(e => e.status === "Assigned")?.length ?? 0})
              </ToggleButton>
              <ToggleButton value="complete" aria-label="Complete">
                Complete ({trainingData?.filter(e => e.status === "Complete")?.length ?? 0})
              </ToggleButton>
             
            </ToggleButtonGroup>
            <Mui.TableContainer>
              <Mui.Table
                className={classes?.table}
                aria-label="training list"
              >
                <Mui.TableHead>
                  <Mui.TableRow>
                    <Mui.TableCell>OSUID</Mui.TableCell>
                    <Mui.TableCell>Last Name</Mui.TableCell>
                    <Mui.TableCell>First Name</Mui.TableCell>
                    <Mui.TableCell>Committee</Mui.TableCell>
                    <Mui.TableCell>Status</Mui.TableCell>
                  </Mui.TableRow>
                </Mui.TableHead>
                <Mui.TableBody>
                  {progressLoading ? (
                    <Mui.TableRow>
                      <Mui.TableCell colSpan={5} component="th" scope="row">
                        <Skeleton variant="rect" width="100%" height="2rem" />
                      </Mui.TableCell>
                    </Mui.TableRow>
                  ) : (
                    trainingLocalData?.map((row) => (
                      <Mui.TableRow
                        key={`${row.applicantEmployeeId}-${row.committeeId}-${row.term}`}
                      >
                        <Mui.TableCell component="th" scope="row">
                          <Mui.Link
                            component={Link}
                            to={`/training/${row.applicantEmployeeId}/${row.committeeId}/${row.term}`}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {row.applicantEmployeeId}
                            </div>
                          </Mui.Link>
                        </Mui.TableCell>
                        <Mui.TableCell>{row?.lastName}</Mui.TableCell>
                        <Mui.TableCell>{row?.firstName}</Mui.TableCell>
                        <Mui.TableCell>
                          {row?.committeeName}
                        </Mui.TableCell>
                        <Mui.TableCell>
                          {row?.status}
                        </Mui.TableCell>
                      </Mui.TableRow>
                    ))
                  )}
                </Mui.TableBody>
              </Mui.Table>
              {!progressLoading && trainingData?.length <= 0 && (
                <Mui.Box className={classes.box}>
                  <Mui.Typography>
                    There are no training applications to display.
                  </Mui.Typography>
                </Mui.Box>
              )}
            </Mui.TableContainer>
          </Mui.Box>
          </Mui.AccordionDetails>
        </Mui.Accordion>
      </Mui.Box>
    </Mui.Box>
  );
};

export default Dashboard;

