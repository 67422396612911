import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
// import Amplify, { Auth } from 'aws-amplify';
import { Amplify } from 'aws-amplify';

import reportWebVitals from './reportWebVitals';
import configureStore from './storeConfig';
import App from './App/containers';
import AppleTouchIcon from './DocumentHead/AppleTouchIcon';
import './styles/index.scss';
import 'normalize.css';
import awsExports from './aws-exports';
import apiConfig from './api-config';
import { AlertBar } from './Common/components/AlertBar';
import RefreshPageAlert from "./Common/JavascriptVersionChecker";
// import CustomAuthStorage from './util/CustomAuthStorage';

// Auth.configure({ storage: new CustomAuthStorage(apiConfig.customAuthStorage) });
Amplify.configure({ ...awsExports, ...apiConfig });
console.log("id:", process.env.REACT_APP_API_ID)
const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <AppleTouchIcon />
        <AlertBar>
          <App darkMode={false} />
        </AlertBar>
      </BrowserRouter>
      <RefreshPageAlert />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
