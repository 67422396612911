import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => ({
  root: {
    display: "flex",
  },
}));

const CompQualities = (props) => {
  const classes = useStyles();
  const {
    studentData,
    renderInputControls
  } = props;
  
  return (
    <Mui.Container>
      <div className={classes.root}>
        <Mui.FormControl component="fieldset" className={classes.formControl}>
          <Mui.FormLabel component="legend">
            {studentData?.data?.notes?.competitive && <Mui.Typography variant="h6" component="h3" >Competitive Application Attributes</Mui.Typography>}
          </Mui.FormLabel>
          <Mui.FormGroup>   
           {renderInputControls(studentData?.data?.notes?.competitive, "competitive")}
          </Mui.FormGroup>
        </Mui.FormControl>
      </div>
    </Mui.Container>
  );
};
export default CompQualities;
