import {
  ASSIGN_APPLICATION,
  FETCH_STUDENT,
  GET_COMMITTEE_REVIEWERS,
  GET_DOCUMENT,
  CLEAR_NOTIFY_ADMIN,
  NOTIFY_ADMIN,
  REASSIGN_APPLICATION,
  REPORT_CONFLICT,
  RESTART_APPLICATION,
  SAVE_REVIEW,
  SEARCH_DOCUMENTS,
  SUBMIT_RECOMMENDATION,
  UNASSIGN_APPLICATION,
  UPDATE_RECOMMENDATION_HISTORY,
  CLEANUP_APPLICANT_DETAIL,
  OVERRIDE_RECOMMENDATION,
  CREATE_TRAINING,
  UPDATE_PRIORITY,
  HOLD_FOR_EXPORT,
  HOLD_FOR_ADMIN,
  EXPECTED_RECOMMENDATION,
  UNASSIGN_TRAINEE,
  GET_TRAINING_CONFIG
} from '../actions-index';
import {
  ERROR_CONSTS,
  RECOMMENDATION_HISTORY_UPDATES,
  STATUS,
} from '../util/constants';

const initialState = {
  status: '',
  alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
};
const initialFullState = {
  student: { ...initialState, type: FETCH_STUDENT },
  applicationDocuments: { ...initialState, data: [], type: SEARCH_DOCUMENTS },
  documentFiles: { ...initialState, documents: [], type: GET_DOCUMENT },
};

export const studentData = (state = initialFullState, action = {}) => {
  switch (action.type) {
    case CLEANUP_APPLICANT_DETAIL:
      return initialFullState;
    case FETCH_STUDENT: {
      // SAS-31 Skip if we've navigated away from the page
      const { datetimePageLoad } = action?.payload ?? {};

      if (action.payload.status !== STATUS.LOADING && 
        (state.student.status !== STATUS.LOADING || state?.student?.datetimePageLoad !== datetimePageLoad)) {
            return state;
        }
      
      return {
        ...state,
        student: {
          status: action.payload.status,
          alert: action.payload.alert,
          data: action.payload.data,
          datetimePageLoad,
          type: FETCH_STUDENT,
        },
      };
    }
    case SEARCH_DOCUMENTS:
      return {
        ...state,
        applicationDocuments: {
          status: action.payload.status,
          showRefresh: action.payload.status ? action.payload.status === STATUS.ERROR : state.applicationDocuments?.showRefresh,
          alert: action.payload.alert,
          data: action.payload.data ?? [],
          type: SEARCH_DOCUMENTS,
        },
      };
    case GET_DOCUMENT: {
      const { datetimeDownloadRequested } = action?.payload ?? {};

      // SAS-31 Ignore if they've navigated away from the applicant detail page
      if (!datetimeDownloadRequested
            || !state?.student?.data?.demographics?.applicantEmployeeId
            || (action.payload.status !== STATUS.LOADING && state?.documentFiles?.datetimeDownloadRequested !== datetimeDownloadRequested)) {
                return state;
            }
      
      return {
        ...state,
        documentFiles: {
          status: action.payload.status,
          alert: action.payload.alert,
          data: action.payload.data,
          datetimeDownloadRequested,
          documents: action.payload.documents,
          type: GET_DOCUMENT,
        },
      };
    }
    case UPDATE_RECOMMENDATION_HISTORY:
      switch (action.payload.actionType) {
        case RECOMMENDATION_HISTORY_UPDATES.ASSIGN:
          return {
            ...state,
            student: {
              ...state?.student,
              data: {
                ...state?.student?.data,
                readers: [
                  ...state?.student?.data?.readers,
                  {
                    readerEmployeeId: action.payload.user.reviewerEmployeeId,
                    readerFirstName: action.payload.user.firstName,
                    readerLastName: action.payload.user.lastName,
                    status: STATUS.ASSIGNED,
                  },
                ],
                recommendationHistory: [
                  ...state?.student?.data?.recommendationHistory,
                  action.payload.user,
                ],
              },
            },
          };
        case RECOMMENDATION_HISTORY_UPDATES.UNASSIGN:
          return {
            ...state,
            student: {
              ...state?.student,
              data: {
                ...state?.student?.data,
                readers: state?.student?.data?.readers?.map((reviewer) => {
                  if (
                    reviewer.readerEmployeeId ===
                    action.payload.reviewerEmployeeId
                  ) {
                    return {
                      ...reviewer,
                      status: STATUS.CANCELLED,
                      data: STATUS.CANCELLED,
                    };
                  } else {
                    return reviewer;
                  }
                }),
                recommendationHistory:
                  state?.student?.data?.recommendationHistory?.map(
                    (reviewer) => {
                      if (
                        reviewer.reviewerEmployeeId ===
                        action.payload.reviewerEmployeeId
                      ) {
                        return { ...reviewer, recommendation: 'Cancelled', recommendationUnified: "Cancelled" };
                      } else {
                        return reviewer;
                      }
                    }
                  ),
              },
            },
          };
        default:
          return state;
      }
      case EXPECTED_RECOMMENDATION:
        return {
          ...state,
          student: {
            ...state?.student,
            status: action.payload.status,
            alert: action.payload.alert,
            type: EXPECTED_RECOMMENDATION,
            data: {
              ...state?.student?.data,
              expectedRecommendation: action.payload.data
            }
          }
        };
    default:
      return state;
  }
};

export const submitDetailsReducer = (
  state = {
    saveReview: { ...initialState, type: SAVE_REVIEW },
    submitRecommendation: { ...initialState, type: SUBMIT_RECOMMENDATION },
  },
  action = {}
) => {
  switch (action.type) {
    case SAVE_REVIEW:
      return {
        ...state,
        saveReview: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: SAVE_REVIEW,
        },
      };
    case SUBMIT_RECOMMENDATION:
      return {
        ...state,
        submitRecommendation: {
          status: action.payload.status,
          alert: action.payload.alert,
          context: action.payload.context,
          type: SUBMIT_RECOMMENDATION,
        },
      };
    default:
      return state;
  }
};

export const toggleHoldForExportReducer = (
  state = {
    toggleHoldForExport: { ...initialState, type: HOLD_FOR_EXPORT},
  }, 
  action = {}
 ) => {
    switch (action.type) {
      case HOLD_FOR_EXPORT:
        return {
          ...state,
          toggleHoldForExport: {
            status: action.payload.status,
            alert: action.payload.alert,
            type: HOLD_FOR_EXPORT,
          },
        };
      default:
        return state;
  }
}

export const toggleHoldForAdminReducer = (
  state = {
    toggleHoldForAdmin: { ...initialState, type: HOLD_FOR_ADMIN},
  }, 
  action = {}
 ) => {
    switch (action.type) {
      case HOLD_FOR_ADMIN:
        return {
          ...state,
          toggleHoldForAdmin: {
            status: action.payload.status,
            alert: action.payload.alert,
            type: HOLD_FOR_ADMIN,
          },
        };
      default:
        return state;
  }
}

export const assignApplicationReducer = (
  state = { assignApplication: { ...initialState, type: ASSIGN_APPLICATION } },
  action = {}
) => {
  switch (action.type) {
    case ASSIGN_APPLICATION:
      return {
        ...state,
        assignApplication: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: ASSIGN_APPLICATION,
        },
      };
    default:
      return state;
  }
};

export const unassignApplicationReducer = (
  state = {
    unassignApplication: { ...initialState, type: UNASSIGN_APPLICATION },
  },
  action = {}
) => {
  switch (action.type) {
    case UNASSIGN_APPLICATION:
      return {
        ...state,
        unassignApplication: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: UNASSIGN_APPLICATION,
        },
      };
    default:
      return state;
  }
};

export const restartApplicationReducer = (
  state = {
    restartApplication: { ...initialState, type: RESTART_APPLICATION },
  },
  action = {}
) => {
  switch (action.type) {
    case RESTART_APPLICATION:
      return {
        ...state,
        restartApplication: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: RESTART_APPLICATION,
        },
      };
    default:
      return state;
  }
};

export const notifyAdminReducer = (
  state = {
    notifyAdmin: { ...initialState, disabled: true, type: NOTIFY_ADMIN },
  },
  action = {}
) => {
  switch (action.type) {
    case NOTIFY_ADMIN:
      return {
        ...state,
        notifyAdmin: {
          status: action.payload.status,
          alert: action.payload.alert,
          disabled: action.payload.disabled,
          type: NOTIFY_ADMIN,
        },
      };
    default:
      return state;
  }
};

export const clearNotifyAdminReducer = (
  state = {
    clearNotifyAdmin: { ...initialState, type: CLEAR_NOTIFY_ADMIN },
  },
  action = {}
) => {
  switch (action.type) {
    case CLEAR_NOTIFY_ADMIN:
      return {
        ...state,
        clearNotifyAdmin: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: CLEAR_NOTIFY_ADMIN,
        },
      };
    default:
      return state;
  }
};


export const committeeReviewerReducer = (
  state = { reviewers: { ...initialState, type: GET_COMMITTEE_REVIEWERS } },
  action = {}
) => {
  switch (action.type) {
    case GET_COMMITTEE_REVIEWERS:
      return {
        ...state,
        reviewers: {
          status: action.payload.status,
          alert: action.payload.alert,
          data: action.payload.data || [],
          type: GET_COMMITTEE_REVIEWERS,
        },
      };

    default:
      return state;
  }
};

export const reassignApplicationReducer = (
  state = {
    reassignApplication: { ...initialState, type: REASSIGN_APPLICATION },
  },
  action = {}
) => {
  switch (action.type) {
    case REASSIGN_APPLICATION:
      return {
        ...state,
        reassignApplication: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: REASSIGN_APPLICATION,
        },
      };
    default:
      return state;
  }
};

export const reportConflictReducer = (
  state = { reportConflict: { ...initialState, type: REPORT_CONFLICT } },
  action = {}
) => {
  switch (action.type) {
    case REPORT_CONFLICT:
      return {
        ...state,
        reportConflict: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: REPORT_CONFLICT,
        },
      };
    default:
      return state;
  }
};

export const overrideRecommendationReducer = (
  state = {
    overrideRecommendation: { ...initialState, type: OVERRIDE_RECOMMENDATION },
  },
  action = {}
) => {
  switch (action.type) {
    case OVERRIDE_RECOMMENDATION:
      return {
        ...state,
        overrideRecommendation: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: OVERRIDE_RECOMMENDATION,
        },
      };
    default:
      return state;
  }
};

export const updatePriorityReducer = (
  state = {
    updatePriority: { ...initialState, type: UPDATE_PRIORITY },
  },
  action = {}
) => {
  switch (action.type) {
    case UPDATE_PRIORITY:
      return {
        ...state,
        updatePriority: {
          status: action.payload.status,
          alert: action.payload.alert,
          type: UPDATE_PRIORITY,
        },
      };
    default:
      return state;
  }
};

export const createTrainingReducer = (
  state = {
    createTraining: {
      ...initialState,
      expectedRecommendation: '',
      emplids: [],
      expJunior: true,
      expIntermediate: true,
      expAdvanced: true,
      expSenior: true,
      jobTypeSeasonal: true,
      jobTypeRegular: true,
      allReviewers: true,
      trainingExists: false,
      recommendationOptions: [],
      decision: '',
      type: CREATE_TRAINING,
    },
  },
  action = {}
) => {
  switch (action.type) {
    case CREATE_TRAINING:
      return {
        ...state,
        createTraining: {
          status: action.payload.status,
          alert: action.payload.alert,
          expectedRecommendation: action.payload.expectedRecommendation ?? state?.createTraining.expectedRecommendation,
          emplids: action.payload.emplids ?? state?.createTraining.emplids,
          expJunior: action.payload.expJunior ?? state?.createTraining.expJunior,
          expIntermediate: action.payload.expIntermediate ?? state?.createTraining.expIntermediate,
          expAdvanced: action.payload.expAdvanced ?? state?.createTraining.expAdvanced,
          expSenior: action.payload.expSenior ?? state?.createTraining.expSenior,
          jobTypeSeasonal: action.payload.jobTypeSeasonal ?? state?.createTraining.jobTypeSeasonal,
          jobTypeRegular: action.payload.jobTypeRegular ?? state?.createTraining.jobTypeRegular,
          allReviewers: action.payload.allReviewers ?? state?.createTraining.allReviewers,
          decision: action.payload.decision ?? state?.createTraining.decision,
          trainingExists: state?.createTraining?.trainingExists ?? false,
          recommendationOptions: state?.createTraining?.recommendationOptions ?? [],
          type: CREATE_TRAINING,
        },
      };

    case GET_TRAINING_CONFIG:
      return {
        ...state,
        createTraining: {
            status: action.payload.status ?? state?.createTraining?.alert,
            ...(state?.createTraining ?? {}),
            alert: action.payload.alert ?? state?.createTraining?.alert,
            trainingExists: action.payload?.trainingExists,
            recommendationOptions: action.payload?.options,
        }
      };

    default:
      return state;
  }
};

export const unassignTraineeReducer = (
  state = {
    unassignTrainee: {
      ...initialState,
      emplids: [],
      type: UNASSIGN_TRAINEE,
    },
  },
  action = {}
) => {
  switch (action.type) {
    case UNASSIGN_TRAINEE:
      return {
        ...state,
        unassignTrainee: {
          status: action.payload.status ?? state.unassignTrainee.status,
          alert: action.payload.alert ?? state.unassignTrainee.alert,
          emplids: action.payload.emplids ?? [],
          type: UNASSIGN_TRAINEE,
        },
      };
    default:
      return state;
  }
};



