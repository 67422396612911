import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: 'space-between'
  },
  backdropContainer: {
    padding: theme.spacing(6),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  success: {
    padding: theme.spacing(3),
  },
  classOfFirstEl: {
    fontWeight: 'bold'
  },
  requiredSubselectionInstructions: {
    fontSize: "90%",
  },
  placeholderWidth: {
    visibility: "hidden",
  }
}));

const Notes = (props) => {
  const classes = useStyles();
  const {
    renderInputControls,
    studentData
  } = props;
  
  function requirementInstructionsEle(areaName) {
    return [
        <Mui.Typography className={`${classes.requiredSubselectionInstructions}`}>* A subfield is required when you select {areaName}</Mui.Typography>,
        <Mui.Typography className={`${classes.requiredSubselectionInstructions} ${classes.placeholderWidth}`}>* A subfield is required when you select {areaName}</Mui.Typography>
    ];
  }
   
  return (
    <Mui.Container>
        <Mui.Box className={classes.root}>
            <Mui.FormControl component="fieldset" className={classes.formControl}>
                <Mui.FormGroup>{renderInputControls(studentData?.data?.notes?.evaluation, "evaluation", ...requirementInstructionsEle("college prep"))}</Mui.FormGroup>
            </Mui.FormControl>
            <Mui.FormControl component="fieldset" className={classes.formControl}>
                <Mui.FormGroup>{renderInputControls(studentData?.data?.notes?.document, "document", ...requirementInstructionsEle("other document"))}</Mui.FormGroup>   
            </Mui.FormControl>
            <Mui.FormControl component="fieldset" className={classes.formControl}>
                <Mui.FormGroup>{renderInputControls(studentData?.data?.notes?.othersConcerns, "othersConcerns")}</Mui.FormGroup>
            </Mui.FormControl>
        </Mui.Box>
    </Mui.Container>
  );
};

export default Notes;
