import React from "react";
import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => {
  return {
    width100: {
      width: "100%",
    },
    listItemText: {
      flexBasis: "50%",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(2),
    },
    listItemLast: {
      flexBasis: "50%",
      display: "flex",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
    dataList: {
      flexDirection: "row",
      flexWrap: "wrap",
    },
  };
});

const HighSchool = (props) => {
  const classes = useStyles();
  const { studentData } = props;

  const highSchoolData = [
    {
      label: "Location",
      value: (studentData?.data?.demographics?.highSchoolLocation || ' - ').replace(/null/g, ' - '),
    },
    {
      label: "Graduation Date",
      value: studentData?.data?.demographics?.highSchoolGraduationDate || ' - ',
    },
    {
      label: "Class Standing/Size",
      value: `${studentData?.data?.demographics?.classStanding || ' - '}/${studentData?.data?.demographics?.classSize || ' - '}`,      
    },
    {
      label: "Class Rank/Type",
      value: `${studentData?.data?.demographics?.classRankPercentile || ' - '}/${studentData?.data?.demographics?.classRankType || ' - '}`,      
    },
    {
      label: "HighSchool GPA",
      value: studentData?.data?.demographics?.highSchoolGPA || ' - ',
    },
    {
      label: "CACR Score",
      value: studentData?.data?.demographics?.cacrScore || ' - ',
    },
  ];

  return (
    <>
      <Mui.Box className={classes.width100}>
        <Mui.Typography variant="subtitle2" component="h3">
          {studentData?.data?.demographics?.highSchoolName}
        </Mui.Typography>
        <Mui.List
          className={`display-flex ${classes.width100} ${classes.dataList}`}
          component="dl"
        >
          {highSchoolData.map(({ label, value }, index) => {
            return (
              <Mui.ListItemText
                key={`list=items-text-hs-${index}`}
                primary={label}
                secondary={value}
                className={
                  index === highSchoolData.length - 1 ||
                  index === highSchoolData.length - 2
                    ? classes.listItemLast
                    : classes.listItemText
                }
                primaryTypographyProps={{
                  component: "dt",
                  className: "margin-right-2",
                  variant: "subtitle2",
                }}
                secondaryTypographyProps={{
                  component: "dd",
                  variant: "body2",
                  color: "textPrimary",
                }}
              />
            );
          })}
        </Mui.List>
      </Mui.Box>
    </>
  );
};

export default HighSchool;
