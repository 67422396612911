import React from "react";
import { Mui } from "@osu/react-ui";

const CollegePrep = (props) => {
  const { studentData } = props;

  const hide = ["domestic-transfer", "international-transfer"].includes(studentData?.data?.demographics?.committeeId) && studentData?.data?.earnedHoursPU >= 30;
  return (
    <Mui.Box>
      <Mui.Table aria-label="simple table">
        <Mui.TableHead>
          <Mui.TableRow>
            <Mui.TableCell                role="columnheader" scope="col">Units</Mui.TableCell>
            <Mui.TableCell align="center" role="columnheader" scope="col">Math</Mui.TableCell>
            <Mui.TableCell align="center" role="columnheader" scope="col">Natural Science</Mui.TableCell>
            <Mui.TableCell align="center" role="columnheader" scope="col">Foreign Language</Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          <Mui.TableRow>
            <Mui.TableCell component="th" role="rowheader" scope="row">Minimum Required Units</Mui.TableCell>
            <Mui.TableCell align="center">{!hide ? studentData?.data?.collegePrep?.minimumRequiredConfiguration?.math : '-'}</Mui.TableCell>
            <Mui.TableCell align="center">{!hide ? studentData?.data?.collegePrep?.minimumRequiredConfiguration?.naturalScience : '-'}</Mui.TableCell>
            <Mui.TableCell align="center">{!hide ? studentData?.data?.collegePrep?.minimumRequiredConfiguration?.foreignLanguage : '-'}</Mui.TableCell>
          </Mui.TableRow>
          <Mui.TableRow>
            <Mui.TableCell component="th" role="rowheader" scope="row">Earned Units</Mui.TableCell>
            <Mui.TableCell align="center">
              {!hide ? studentData?.data?.collegePrep?.earnedMath : "-"}
            </Mui.TableCell>
            <Mui.TableCell align="center">
              {!hide ? studentData?.data?.collegePrep?.earnedScience : "-"}
            </Mui.TableCell>
            <Mui.TableCell align="center">
              {!hide ? studentData?.data?.collegePrep?.earnedForeign : "-"}
            </Mui.TableCell>
          </Mui.TableRow>
          <Mui.TableRow>
            <Mui.TableCell component="th" role="rowheader" scope="row">Difference Between Minimum and Earned</Mui.TableCell>
            <Mui.TableCell align="center">
              {!hide ? studentData?.data?.collegePrep?.earnedMath - studentData?.data?.collegePrep?.minimumRequiredConfiguration?.math : "-"}
            </Mui.TableCell>
            <Mui.TableCell align="center">
              {!hide ? studentData?.data?.collegePrep?.earnedScience - studentData?.data?.collegePrep?.minimumRequiredConfiguration?.naturalScience : "-"}
            </Mui.TableCell>
            <Mui.TableCell align="center">
              {!hide ? studentData?.data?.collegePrep?.earnedForeign - studentData?.data?.collegePrep?.minimumRequiredConfiguration?.foreignLanguage : "-"}
            </Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableBody>
      </Mui.Table>   
    </Mui.Box>
  );
};
export default CollegePrep;
