import { lazy } from "react";
import LogIn from "../Authentication/components/LogIn";
import LogOut from "../Authentication/components/LogOut";
import Unauthorized from "./views/Unauthorized";
import Dashboard from "../Dashboard/containers";
import Applicant from "../Applicant/containers";

const ManageApplications = lazy(() => import("../ManageApplications/containers"));
const ManageCommittees = lazy(() => import("../ManageCommittees/containers"));
const ManageReviewersBulkAssign = lazy(() => import("../ManageReviewersBulkAssign/containers"));
const ManageActiveReviewers = lazy(() => import("../ManageActiveReviewers/containers"));
const ManageReviewer = lazy(() => import("../ManageReviewer/containers"));
const ManageAdmissionsCycles = lazy(() => import("../ManageAdmissionsCycles/containers"));
const ReportsDash = lazy(() => import("../ReportsDash/containers"));
const ReportPending = lazy(() => import("../ReportPending/containers"));
const ReportPastDue = lazy(() => import("../ReportPastDue/containers"));
const ReportAdminReviews = lazy(() => import("../ReportAdminReviews/containers"));
const ReportRecommendations = lazy(() => import("../ReportRecommendations/containers"));
const ReportTraining = lazy(() => import("../ReportTraining/containers"));
const ReportNotifyAdmin = lazy(() => import("../ReportNotifyAdmin/containers"));
const ReportComplete = lazy(() => import("../ReportComplete/containers"));
const ReportAthenaCustom = lazy(() => import("../ReportAthenaCustom/containers"));
const ReportsExports = lazy(() => import("../ReportsExports/containers"));
const AuditLookup = lazy(() => import("../AuditLookup/containers"));
const AuditReadOnlyView = lazy(() => import("../AuditReadOnlyView/containers"));

let PATHS = [
  {
    title: ({ username }) => `Hello${username ? `, ${username}!`:'!'}`,
    /*
        title: Can be a function that is provided with match, location and history (route params) (https://reactrouter.com/web/api/Route/render-func). 
        */
    /*
        authRequired: true or false, by default, we see if there is an environment / global setting for FORCED_AUTHENTICATION.
            On an auth required app, this flag will default to true and can be overridden here to false.
            On an public facing app, this flag will can be set to true to indicate that a user needs to be logged in to view this page.
        
        */
    path: "/",
    /*
        noIndex: true or false, 
            https://developers.google.com/search/docs/advanced/crawling/block-indexing
            If a page requires auth, we automatically configure seo bots to ignore the page. 
            This is an additional flag for pages like search, where you do not want google to perform seo crawls
        */
    Component: Dashboard,
  },
  {
    title: "Applicant Details",
    path: "/applicant/:applicantId/:committeeId?/:admitTerm?",
    containerSize: "lg",
    Component: Applicant,
  },
  {
    title: "Search",
    path: "/search",
    containerSize: "lg",
    Component: AuditLookup,
  },
  {
    title: "Applicant Details",
    path: "/view/:applicantId/:committeeId/:admitTerm",
    containerSize: "lg",
    Component: AuditReadOnlyView,
  },
  {
    title: "Training - Applicant Details",
    path: "/training/:applicantId/:committeeId/:admitTerm",
    containerSize: "lg",
    Component: Applicant,
  },
  {
    title: "Manage Reviewer Goals",
    path: "/manage-reviewer-goals/:committeeId?",
    Component: ManageReviewersBulkAssign,
  },
  {
    title: "Manage Reviewers",
    path: "/manage-reviewers/:committeeId?",
    Component: ManageActiveReviewers,
  },
  {
    title: "Manage Reviewer",
    path: "/manage-reviewer/:reviewerEmployeeId?",
    Component: ManageReviewer,
  },
  {
    title: "Manage Applications",
    path: "/manage-applications/:committeeIdFromUrl/:reviewerEmployeeIdFromUrl?",
    Component: ManageApplications,
  },
  {
    title: "Reports",
    path: "/reports",
    Component: ReportsDash,
  },
  {
    title: "Applications - In Review",
    path: "/reports/inreview/:committeeIdFromUrl?",
    Component: ReportPending,
  },
  {
    title: "Applications - Not Started",
    path: "/reports/notstarted/:committeeIdFromUrl?",
    Component: ReportPending,
  },
  {
    title: "Reviews - Past Due",
    path: "/reports/pastdue/:committeeIdFromUrl?",
    Component: ReportPastDue,
  },
  {
    title: "Admin Reviews",
    path: "/reports/adminreviews/:committeeIdFromUrl?",
    Component: ReportAdminReviews,
  },
  {
    title: "Request Additional Information",
    path: "/reports/requestadditionalinformation/:committeeIdFromUrl?",
    Component: ReportAdminReviews,
  },
  {
    title: "Recommendations To Approve",
    path: "/reports/recommendations/:committeeIdFromUrl?",
    Component: ReportRecommendations,
  },
  {
    title: "Applications - Completed and Exported",
    path: "/reports/complete/:committeeIdFromUrl?",
    Component: ReportComplete,
  },
  {
    title: "Notify Admin",
    path: "/reports/notifyadmin/:committeeIdFromUrl?",
    Component: ReportNotifyAdmin,
  },
  {
    title: "Training",
    path: "/reports/training/:committeeIdFromUrl?",
    Component: ReportTraining,
  },
  {
    title: ({ reportName }) => reportName ?? " ",
    path: "/reports/custom/:reportId/:committeeIdFromUrl",
    Component: ReportAthenaCustom,
  },
  {
    title: "Exports",
    path: "/exports/:committeeId?",
    Component: ReportsExports,
  },
  {
    title: "Manage Committees",
    path: "/manage-committees",
    Component: ManageCommittees,
  },
  {
    title: "Manage Admissions Cycles",
    path: "/manage-cycles",
    Component: ManageAdmissionsCycles,
  },
];

if(process.env.REACT_APP_ENABLE_MANAGE_READERS === "false") {
    PATHS = PATHS.filter((obj) => {
      return !obj.path.includes("manage-reviewer");
    });
}

const authenticationFlowPaths = [
  {
    path: "/login",
    Component: LogIn,
  },
  {
    path: "/logout",
    Component: LogOut,
  },
  {
    path: "/unauthorized",
    Component: Unauthorized,
  },
];

export { authenticationFlowPaths, PATHS as default };
