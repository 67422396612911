import { Mui } from "@osu/react-ui";
import Chip from "./scoresChip";
import useStyles from '../../dd-styles';

const BestTest = (props) => {
  const { studentData } = props;
  const classes = useStyles();

  return studentData?.data?.bestTest?.score !== "" ? (
    <>
      <Mui.Typography tabindex="0" component="h3" variant="h6" >
        Best Test
      </Mui.Typography>
      <dl title="Best Test" className={classes.dl}>
        <dd tabindex="0" className={classes.dd}>
          <Chip label={"Score"} childLabel={studentData?.data?.bestTest?.score} />
        </dd>
      </dl>
      </>
  ) : (
    <></>
  );
};
export default BestTest;
