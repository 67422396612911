import Skeleton from "@material-ui/lab/Skeleton";
import { Mui, TOC, scrollToHash } from "@osu/react-ui";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";
import { OVERRIDE_RECOMMENDATION, SUBMIT_RECOMMENDATION } from "../../actions-index";
import { ERROR_CONSTS, RECOMMENDATIONS, SEVERITY_LEVELS, STATUS } from "../../util/constants";
import { validate, exportActionVariable, exportStringArray, formsReducer, sectionIsDisabled } from "../NotesState";
import sections from "../components/sections";

const sortBySortPriority = (arr = []) =>
  arr.sort((a, b) => (a.sortPriority > b.sortPriority ? 1 : -1));

const useStyles = Mui.makeStyles((theme) => {
  return {
    root: {
      marginBottom: theme.spacing(1),
    },
    button: {
      marginBottom: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    committee: {
      borderColor: "green",
      color: "green",
    },
    success: {
      padding: theme.spacing(2),
    },
    expanded: {
      marginBottom: "0 !important",
      "&.Mui-selected": {
        marginBottom: "0 !important"
      }
    }
  };
});

function Applicant(props) {
  let history = useHistory();
  const location = useLocation();
  let decodedApps, encodedApps, nextApp;    
  const searchParams = new URLSearchParams(location.search);
  const submitErrorLabelRef = useRef();

  const todo = searchParams.get("todo");

  if(todo) {
    //get query string items
    //query contains ?todo= and &from=
    decodedApps = JSON.parse(decodeURIComponent(todo))
    nextApp = decodedApps[0];
    decodedApps.shift();
    encodedApps = encodeURIComponent(JSON.stringify(decodedApps));
  }
  const dispatch = useDispatch()
  const authDetails = useRoleAuthentication();
  //console.log("authDetails", authDetails);
  //allows admin view to not see buttons at bottom and also to override in actions button
  const { pathname } = history?.location;
  

  const [show, setShow] = useState(true);
  const [isOverrideDecision, setIsOverrideDecision] = useState(false);
  const [adminReason, setAdminReason] = useState(""); 
  

  if (authDetails?.UNAUTHORIZED) {
    history.push("/unauthorized");
  }
  const classes = useStyles();
  const { applicantId, committeeId, admitTerm } = useParams();

  const {
    submitState,
    fetchStudent,
    setPageAppHeader,
    getReviewers,
    userId,
    searchDocuments,
    applicationDocuments,
    documentFiles,
    getDocument,
    student: studentData,
    cleanup,
    overrideRecommendation,
    submitTraining,
    trainingConfig,
    toggleHoldForExport,
    toggleHoldForExportState,
    toggleHoldForAdmin,
    getExpectedRecommendation,
    getTrainingConfig,
  } = props;

  const [recHistorySub, setRecHistorySub] = useState(false);
  
  const name = studentData?.data?.demographics?.firstName ? 
  studentData?.data?.demographics?.firstName +
  " " +
  studentData?.data?.demographics?.middleInitial +
  " " +
  studentData?.data?.demographics?.lastName
  : null;
  const committee = studentData?.data?.demographics?.committeeId;
  
  const [expectedRecommendation, setExpectedRecommendation] = useState(false);
  useEffect(() => {
    if(studentData?.data?.decisionOverride) {
      setDecision(studentData?.data?.decisionOverride)
    } else if (studentData?.data?.decision) {
      setDecision(studentData?.data?.decision)
    }
  }, [studentData?.data?.decision])

  const status = studentData?.status
  let loading = status === STATUS.LOADING;
  let isLoadingError = status === STATUS.ERROR;
  const isTraining = pathname?.includes("training");
  const queryKey = 'searchApplicationDocuments'
  const [fetchedTrainingConfig, setFetchedTrainingConfig] = useState(false)

  //API CALL FUNCTION
  useEffect(() => {
      setDecision(""); // Reset currently selected decision
      fetchStudent({
        queryKey: isTraining ? "getTraining" : "getApplication",
        committeeId: committeeId,
        applicantEmployeeId: applicantId,
        admitTerm: admitTerm,
        recHistorySub: recHistorySub, 
        setRecHistorySub: setRecHistorySub,
    });

    // Pull back onBase documents
    const applicantEmployeeId = applicantId;
    const input = { applicantEmployeeId, admitTerm, committeeId, isTraining, queryKey }
    searchDocuments(input);
  }, [fetchStudent, committeeId, applicantId, admitTerm, searchDocuments, pathname, setRecHistorySub]);
  
  const refreshDocuments = () => searchDocuments({ applicantEmployeeId: applicantId, admitTerm, committeeId, isTraining, queryKey });


  useEffect(() => {
    if (submitState?.status === STATUS?.SUCCESS && (submitState?.type === SUBMIT_RECOMMENDATION || submitState?.type === OVERRIDE_RECOMMENDATION)) {
      setOpen(false);
      setOpenSuccess(true);
    }
  }, [submitState])  

  //code below is when training is completed by user, the training page becomes a readOnly page
  const userIsReader = studentData?.data?.readers?.find(user => user.readerEmployeeId === userId); 
  const readOnly = userIsReader?.status?.includes("#TRAINING_COMPLETE");
  const notifyAdmin = userIsReader?.adminAction === "NOTIFY";
  
  useEffect(() => {
    if (
      readOnly && !expectedRecommendation
    ) {
      setExpectedRecommendation(true);
      getExpectedRecommendation(
        studentData?.data?.demographics?.applicantEmployeeId,
        studentData?.data?.demographics?.committeeId,
        studentData?.data?.demographics?.admitTerm?.key,
      );
    }
  }, [userIsReader?.status]);

  useEffect(() => { 
      if (!fetchedTrainingConfig && !isTraining && authDetails.ADMIN) {
        setFetchedTrainingConfig(true)
        getTrainingConfig(applicantId, committeeId, admitTerm);
      }
  }, [applicantId, committeeId, admitTerm, location?.pathname, authDetails]);
  
  const [decision, setDecision] = useState("");
  const [noteState, noteDispatch] = useReducer(formsReducer, []);

  const renderInputControls = (notes, section, requirementInstructionsEle, invisibleEle) => {
    let fields = [];
    let parentLabelExistsAndIsChecked = false;

    for (let note of notes) {
      const noteStateItem = noteState.find(item => item.label === note.label && item.section === section);
      const parentLabelExists = note?.parentLabel ? true : false;
      const parentLabelExistsAndIsNotChecked = parentLabelExists && !noteState.find(item => item.label === note?.parentLabel)?.value ? true : false;
      
      parentLabelExistsAndIsChecked = parentLabelExists && !parentLabelExistsAndIsNotChecked;
      const asteriskAfterLabel = parentLabelExistsAndIsChecked ? "\u00A0*" : "";
      
      fields.push(
        <Mui.FormControlLabel
          control={
            <Mui.Checkbox
              checked={!!(noteStateItem?.value)}
              onChange={!readOnly ? (val) => noteDispatch({...val, section: section}) : undefined}
              color="primary"
              name={note.label}
              disabled={sectionIsDisabled(studentData?.data?.notes, noteState, section, !!(noteStateItem?.value)) || parentLabelExistsAndIsNotChecked}
              style={{
                paddingLeft: note?.parentLabel && '2rem',
                color: note?.parentLabel && noteState.find(item => item.label === note?.parentLabel)?.value && !exportActionVariable(noteState, noteStateItem?.section).find((item) => item.parentLabel && item.value) && 'red'
              }}
              />
            }
          key={note.label}
          label={`${note.label}${asteriskAfterLabel}`}
        />
      );
    }

    //sorts these alphabetically except for "Displays none of these" which is going to the bottom  
    fields = fields.sort((a, b) => {
      if (a.key.startsWith("Displays") && !b.key.startsWith("Displays")) {
        return 1;
      } else if (!a.key.startsWith("Displays") && b.key.startsWith("Displays")) {
        return -1;
      } else {
        return a.key.localeCompare(b.key);
      }
    });
    
    if (requirementInstructionsEle && parentLabelExistsAndIsChecked) {
        fields.push(requirementInstructionsEle); // Show instructions at the end
    } else {
        fields.push(invisibleEle); // Reserve space to prevent elements from jumping around
    }
    return fields;
  }

  const [openSuccess, setOpenSuccess] = useState(false);
  const handleCloseSuccess = () => {
    setOpenSuccess(false);
    setOpenAlertSuccess(false);
    dispatch({
      type: SUBMIT_RECOMMENDATION,
      payload: {
        status: null,
        alert: ERROR_CONSTS?.UI?.GENERIC?.EMPTY_ERROR_OBJECT,
        details: '',
      },
    });
    if(!nextApp)
      history.push("/");
    else
      history.push(`/applicant/${nextApp?.applicationid}/${nextApp?.committeeId}/${nextApp?.term}?todo=${encodedApps}`);
  };
  const [openAlertSuccess, setOpenAlertSuccess] = useState(false);

  const handleSubmit = () => {  
    const checkedRestriction = exportStringArray(noteState, "restrictions").toString();
    if (isOverrideDecision) {
      overrideRecommendation({
        applicantEmployeeId: studentData?.data?.demographics?.applicantEmployeeId,
        committeeId: studentData?.data?.demographics?.committeeId,
        term: studentData?.data?.demographics?.admitTerm?.key,
        recommendation: decision,
        restriction: checkedRestriction,
        comments: adminReason,
      })
    } else {    
      setPageAppHeader(decision);

      const checkedCompetitive = exportStringArray(noteState, "competitive");
      const checkedNoncompetitive = exportStringArray(noteState, "noncompetitive");
      const checkedDocument = exportStringArray(noteState, "document");
      const checkedEvaluation = exportStringArray(noteState, "evaluation");
      const checkedOther = exportStringArray(noteState, "othersConcerns");      

      if (decision === RECOMMENDATIONS?.ADMIT_WITH_RESTRICTION && checkedRestriction.length === 0) {
        dispatch({
          type: SUBMIT_RECOMMENDATION,
          payload: {
            status: STATUS.ERROR,
            alert: {
              MESSAGE: "Please choose a restriction from the list.",
              SEVERITY: SEVERITY_LEVELS.WARNING,
            },
          },
        });
        return;
      }

      if (history?.location?.pathname.includes('/training/')) {
        submitTraining({
          admitTerm: studentData?.data?.demographics?.admitTerm?.key,
          applicantEmployeeId: studentData?.data?.demographics?.applicantEmployeeId,
          committeeId: studentData?.data?.demographics?.committeeId,
          recommendation: decision,
          restriction: checkedRestriction,
          reviewNotes: {
            competitive: checkedCompetitive,
            document: checkedDocument,
            evaluation: checkedEvaluation,
            noncompetitive: checkedNoncompetitive,
            othersConcerns: checkedOther,
          },
          isTraining: true,
        })
      } else {
          props.submitReview({
            admitTerm: studentData?.data?.demographics?.admitTerm?.key,
            applicantEmployeeId: studentData?.data?.demographics?.applicantEmployeeId,
            committeeId: studentData?.data?.demographics?.committeeId,
            recommendation: decision,
            restriction: checkedRestriction,
            reviewNotes: {
              competitive: checkedCompetitive,
              document: checkedDocument,
              evaluation: checkedEvaluation,
              noncompetitive: checkedNoncompetitive,
              othersConcerns: checkedOther,
            },
          })
      }
    }
  };
  
  useEffect(() => {
    if (studentData) {
      //reset to nothing
      noteDispatch({ type: "RESET" });

      //add all of the possible fields
      noteDispatch({ type: "ADD", payload: studentData?.data?.notes?.competitive, section: "competitive" });
      noteDispatch({ type: "ADD", payload: studentData?.data?.notes?.noncompetitive, section: "noncompetitive" });
      noteDispatch({ type: "ADD", payload: studentData?.data?.notes?.document, section: "document"  });
      noteDispatch({ type: "ADD", payload: studentData?.data?.notes?.evaluation, section: "evaluation" });
      noteDispatch({ type: "ADD", payload: studentData?.data?.notes?.othersConcerns, section: "othersConcerns" });
      //noteDispatch({ type: "ADD", payload: studentData?.data?.notes?.restrictions, section: "restrictions" });
   
      let currentReviewer = studentData?.data?.readers?.find((reviewer) => reviewer.readerEmployeeId === userId && [STATUS.ASSIGNED, STATUS.RESUMELATER].includes(reviewer.status));
      if (currentReviewer) {
        for (let objItem in currentReviewer.reviewNotes) {
          for (let item of currentReviewer.reviewNotes[objItem] ?? []) {
            noteDispatch({ target: { checked: true, name: item.label, type: "checkbox" }, section: objItem })
          }
        }
      }
    }
  }, [studentData, userId]);

  const displayNoCompetitive = noteState.find((item) => {
    return item.section === "competitive" && item.label === "Displays none of these attributes";
  });
  const displayNoNoncompetitive = noteState.find((item) => {
    return item.section === "noncompetitive" && item.label === "Displays none of these attributes";
  });
  
  useEffect(() => {
    if (displayNoCompetitive?.value === true) {
      // Uncheck all items in the "competitive" section except for "Displays none of these attributes"
      noteState?.map((item) => {
        if ((item.section === "competitive" && item.label !== "Displays none of these attributes")) { 
        item.value = false;     
        }
        noteDispatch({ target: { checked: false, name: item.label, section: "competitive",  type: "checkbox" } })
      });
    }
  }, [displayNoCompetitive?.value]);

  useEffect(() => {
    if (displayNoNoncompetitive?.value === true) {
      // Uncheck all items in the "noncompetitive" section except for "Displays none of these attributes"
      noteState?.map((item) => {
        if ((item.section === "noncompetitive" && item.label !== "Displays none of these attributes")) { 
        item.value = false;     
        }
        noteDispatch({ target: { checked: false, name: item.label, section: "noncompetitive",  type: "checkbox" } })
      });
    }
  }, [displayNoNoncompetitive?.value]);

  const isCompetitiveChecked = noteState?.some((item) => {
  const isCompChecked = item.value;
  const isCompetitive = item.section === "competitive";
    return isCompetitive && isCompChecked;
  });
    
  const isNoncompetitiveChecked = noteState?.some((item) => {
  const isNoncompChecked = item.value;
  const isNoncompetitive = item.section === "noncompetitive";
    return isNoncompetitive && isNoncompChecked;
  });
  const areCheckboxesChecked = (isCompetitiveChecked || isNoncompetitiveChecked);
    //console.log("areCheckboxesChecked", areCheckboxesChecked);

    //check to see if any checkboxes are checked in competitive or noncompetitive sections except for displayNone and if so uncheck displayNone
  const competitiveCheckboxesChecked = noteState?.some((item) => {
  const isChecked = item.value;
  const isCompetitive = item.section === "competitive";
    return (
      isCompetitive && item !== displayNoCompetitive && isChecked
      );
  });
    //console.log("competitiveCheckboxesChecked", competitiveCheckboxesChecked);
  const noncompetitiveCheckboxesChecked = noteState?.some((item) => {
  const isChecked = item.value;
  const isNoncompetitive = item.section === "noncompetitive";
    return (
      isNoncompetitive && item !== displayNoNoncompetitive && isChecked
      );
  });
    //console.log("noncompetitiveCheckboxesChecked", noncompetitiveCheckboxesChecked);
      
  useEffect(() => {
    if (competitiveCheckboxesChecked === true) {
        noteState?.map((item) => {
          if ((item.section === "competitive" && item.label === "Displays none of these attributes")) { 
          item.value = false;     
          }
        noteDispatch({ target: { checked: false, name: item.label, type: "checkbox" } })
      });
    }
    if (noncompetitiveCheckboxesChecked === true) {
        noteState?.map((item) => {
          if ((item.section === "noncompetitive" && item.label === "Displays none of these attributes")) { 
          item.value = false;     
          }
        noteDispatch({ target: { checked: false, name: item.label, type: "checkbox" } })
      });
    }
  }, [competitiveCheckboxesChecked, noncompetitiveCheckboxesChecked]);

  useEffect(() => {
    // clear alert on location change
    const unlisten = history.listen((loc) => {
      if(loc.pathname.includes("/applicant/") || loc.pathname.includes("/training/"))
        return;
      //call clear redux
      cleanup();
    });

    // stop the listener when component unmounts
    return unlisten;
  }, []);
  
  useEffect(() => {
    if (Array.isArray(documentFiles?.data) && documentFiles?.data?.length > 0) {
      for (let doc of documentFiles?.data) {
        if (doc.status !== STATUS.SUCCESS) { continue; }
        window.open(doc.signedUrl, "_blank");
      }
    }

  }, [documentFiles]);

  const term = studentData?.data?.demographics?.admitTerm;

  //filtering TOC sections based on authDetails and committees and credithour totals(much of the logic is in sections.js)
  const creditHours = studentData?.data?.universityInfo?.map?.((item) => parseInt(item.completedUnits));
  const totalCreditHours = creditHours?.reduce((a, b) => a + b, 0);
  const defaultCreditHours = process.env.REACT_APP_CREDIT_HOURS ?? 30;
  const applicationStatus = studentData?.data?.demographics?.status;
  const customData = { authDetails, applicationStatus, isTraining, location };
  const sectionsFiltered = (committee === "international-transfer" || committee === "domestic-transfer") && totalCreditHours >= defaultCreditHours ? sections?.filterAuth(authDetails, customData)?.filter((item) => !["collegePrep", "highschoolUnits"]?.includes(item.id)) : sections?.filterCombined(authDetails, committee, customData);

  useEffect(() => {
    authDetails.ADMIN && committee && getReviewers({ committeeId: committee })
  }, [authDetails.ADMIN, committee, getReviewers])

  useEffect(() => {
    if (authDetails?.ADMIN === true && authDetails?.ADMIN_REVIEWER !== true && applicationStatus !== "ADMINREVIEW") {
      setShow(false);
    }
    else {
      setShow(true);
    }
  }, [authDetails?.ADMIN, authDetails?.ADMIN_REVIEWER, applicationStatus]);

  const handleUnlock = () => {
    setShow(true);
    setIsOverrideDecision(true);
    scrollToHash(document, "recommendation-heading");
  };
  
  const handleDecision = (value) => {
    setDecision(value);
    setSubmitError(false);
  };
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleCloseBackDrop = () => {
    setOpen(false);
    setOpenAlert(false);
  };
  const handleAlert = () => {
    setOpenAlert(false);
    setOpenAlertSuccess(true);
  };
  const [submitError, setSubmitError] = useState(false);
  const [notesError, setNotesError] = useState(false);

  const college= Object.keys(noteState).find((item) => item === "College Prep Units Appear Inaccurate");
  const other= Object.keys(noteState).find((item) => item === "Other Document Inaccurate");

  useEffect(() => {
  for(let item in noteState) { 
    if((item === college || item === other) && noteState[item].value === false)  
      setNotesError(false);              
    } 
  }, [college, other, noteState]);

  useEffect(() => {
    if (validate(noteState) ) {
      setNotesError(false);
    }   
  }, [noteState]);

  const handleToggle = useCallback(() => {  
      let notesClear = false
      if (!validate(noteState)) {
        setNotesError(true);
        submitErrorLabelRef.current.focus()
    } else {
        notesClear = true
      }   
      if (notesClear) {
        if (decision === "") {
          setSubmitError(true);
          submitErrorLabelRef.current.focus()
        } else if (isOverrideDecision && adminReason === "")  {
          setSubmitError(true);
          submitErrorLabelRef.current.focus();
        } else if (!areCheckboxesChecked && !isOverrideDecision)  {
          setSubmitError(true);
          submitErrorLabelRef.current.focus();
        } else {
          setOpen(true);
        }
      }
}, [noteState, decision, adminReason, isOverrideDecision]);

  useEffect(() => {
    setPageAppHeader({
      name,
      committee: studentData?.data?.demographics?.committeeId,
      committeeName: studentData?.data?.committeeDetails?.committeeName,
      emplId: studentData?.data?.demographics?.applicantEmployeeId,
      dateOfBirth: studentData?.data?.demographics?.dateOfBirth,
      restriction: studentData?.data?.readers?.find((reviewer) => reviewer.readerEmployeeId === userId)?.restriction,
      unlock: handleUnlock,
      authDetails,
      decision,
      noteState,
      userId,
      handleToggle,
      handleDecision,
      handleSubmit,
      loading,
      priority: studentData?.data?.priority,
      term,
      applicantTrainingExists: trainingConfig?.trainingExists
    });
  }, [authDetails, decision, handleToggle, handleDecision, handleSubmit, committee, loading, name, noteState, setPageAppHeader, term, trainingConfig?.trainingExists]);
  
  useEffect(() => {
    // SAS-31 SAS-32, prevent documents from re-opening and unauthorized pages for SLT users
    return cleanup;
  }, []);
  
  return (
    <>
      {loading && (
        <Mui.Box>
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" height="64px"></Skeleton>
          <br />
          <Skeleton variant="rect" width={100} height={"1em"}></Skeleton>
        </Mui.Box>
      )}
      

      {!loading && !isLoadingError && (
        <>
          {sortBySortPriority(sectionsFiltered).map(
            (
              { id, heading, sortPriority, Component: ChildComponent, defaultCollapsed },
              index
            ) => (
              <TOC.Section
                expandable
                defaultExpanded={!sections?.checkDefaultCollapsed(defaultCollapsed, authDetails)}
                className={`${classes.root} ${classes.accordion}`}
                id={id}
                key={`${index}${id}-jumpsection`}
                heading={heading}
                sortPriority={sortPriority}
                accordionSummaryProps={{
                  classes: {
                    content: classes.expanded
                  }
                }}
                AnchorHeadingProps={{
                  variant:  "h5",
                  component: "h2"
                }}
              >
                  <ChildComponent
                    handleUnlock={handleUnlock}
                    name={name}
                    committee={committee}
                    studentData={studentData}
                    decision={decision}
                    open={open}
                    setOpen={setOpen}
                    openAlert={openAlert}
                    show={show}
                    setShow={setShow}
                    handleDecision={handleDecision}
                    handleCloseBackDrop={handleCloseBackDrop}
                    handleCloseSuccess={handleCloseSuccess}
                    handleToggle={handleToggle}
                    handleSubmit={handleSubmit}
                    handleAlert={handleAlert}
                    readOnly={readOnly}
                    notifyAdmin={notifyAdmin}
                    userIsReader={userIsReader}
                    openSuccess={openSuccess}
                    openAlertSuccess={openAlertSuccess}
                    submitError={submitError}
                    notesError={notesError}
                    areCheckboxesChecked={areCheckboxesChecked}
                    setSubmitError={setSubmitError}
                    isOverrideDecision={isOverrideDecision}
                    noteState={noteState}
                    noteDispatch={noteDispatch}
                    renderInputControls={renderInputControls}
                    applicationDocuments={applicationDocuments}
                    documentFiles={documentFiles}
                    getDocument={getDocument}
                    isTraining={isTraining}
                    adminReason={adminReason}
                    setAdminReason={setAdminReason}
                    applicationStatus={applicationStatus}
                    toggleHoldForExport={toggleHoldForExport}
                    toggleHoldForAdmin={toggleHoldForAdmin}
                    admitTerm={admitTerm}
                    toggleHoldForExportState={toggleHoldForExportState}
                    encodedApps={encodedApps}
                    nextApp={nextApp}
                    getExpectedRecommendation={getExpectedRecommendation}
                    refreshDocuments={refreshDocuments}
                    submitErrorLabelRef={submitErrorLabelRef}
                  />
                </TOC.Section>
            )
          )}
        </>
      )}
    </>
  );
}

export default Applicant;