import { Mui } from '@osu/react-ui';
import Chip from './scoresChip';
import useStyles from '../../dd-styles';

const SATScores = (props) => {
  const { studentData } = props;
  const classes = useStyles();

  return (
    <>
      <Mui.Typography tabindex="0" aria-label='S A T' component="h3" variant="h6" key={1}>
        SAT
      </Mui.Typography>
      {studentData?.data?.scores?.sat?.length > 0 ? (
        <Mui.Box style={{display:'flex', flexWrap: 'wrap'}}>
          {studentData?.data?.scores?.sat?.map?.((item, index) => (
            <dl title="S A T" key={index} className={classes.dl}>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Date'}
                  childLabel={item.map((item) => item.date)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Total Score'}
                  childLabel={item.map((item) => item.total)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Math'}
                  childLabel={item.map((item) => item.math)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Evidence Based Read/Write'}
                  childLabel={item.map((item) => item.evidenceBased)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Reading'}
                  childLabel={item.map((item) => item.reading)}
                />
              </dd>
              <Mui.Divider />
            </dl>
          ))}
        </Mui.Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default SATScores;
