import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => {
  return {
    dl: {
      display: 'flex',
      flexWrap: 'wrap',
      // display: "grid",
      // grdTemplateColumns: "max-content auto"
      padding: 0,
      margin: 0,
      // width: '1rem'
    },
    dt: {
      padding: 0,
      margin: 0,
    },
    dd: {
      // display: 'flex',
      padding: 0,
      margin: 0,
    },
  };
});

export default useStyles;
