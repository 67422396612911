import React from "react";
import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => {
  return {
    nestedChip: {
      backgroundColor: "white",
      maxHeight: "25px",
      color: "black",
      marginLeft: theme.spacing(1),
    },
    parentChip: {
      minHeight: "38px",
      margin: theme.spacing(1),
    },
  };
});

const Chip = (props) => {
  const classes = useStyles();
  const {label, childLabel} = props;
  return ( 
    <Mui.Chip
              component="dd"
              classes={{
                root: classes.parentChip,
              }}
              label={
                <Mui.Typography component="span" style={{fontSize: "15px"}}>
                  {label}
                  <Mui.Chip
                    component="span"
                    classes={{
                      root: classes.nestedChip,
                    }}
                    label={childLabel}
                  />
                </Mui.Typography>
              }
            />
  );
};
export default Chip;