export const STORAGEKEY_VISIBLEROWS = "UGA-VISIBLE_ROWS_REPORTS";
export const defaultVisibleRows = 10;

export const defaultVisibleColumns = {
  pending: [ "applicantEmployeeId", "lastName", "firstName", "stage", "term", "campusDescription", "residencyCategory", "applicationDate", "earlyAction", "onTime", "academicProgramDescription", "academicPlan"],
  inreview: [ "applicantEmployeeId", "lastName", "firstName", "stage", "assignable", "term", "campusDescription", "residencyCategory", "applicationDate", "earlyAction", "onTime", "academicProgramDescription", "academicPlan"],
  pastdue: [ 'readerEmployeeId', "readerLastName", "readerFirstName", "count", "completedByDueDate"],
  adminreviews: [ "applicantEmployeeId", "lastName", "firstName", "term", "campusDescription", "residencyCategory", "applicationDate", "approvalDate", "cumulativePUGPA", "earnedHoursPU", "cacrScore", "earlyAction", "onTime" ],
  requestadditionalinformation: [ "applicantEmployeeId", "lastName", "firstName", "term", "campusDescription", 'residencyCategory', 'exportedAdminReview', 'exportedAdminReviewDate', 'applicationDate', 'approvalDate', "cumulativePUGPA", "earnedHoursPU", 'cacrScore', 'documentUpdatedDatetime' ],
  recommendations: [ "applicantEmployeeId", "lastName", "firstName", "recommendation", "holdForAdminDate", "term", "campusDescription", 'admitType', 'residencyCategory', "applicationDate", "cumulativePUGPA", "earnedHoursPU", "cacrScore", "earlyAction", "onTime" ],
  complete: [ "applicantEmployeeId", "lastName", "firstName", "recommendation", "term", "campusDescription", "residencyCategory", "applicationDate", "cumulativePUGPA", "earnedHoursPU", "cacrScore", "earlyAction", "onTime"],
  training: [ "applicantEmployeeId", "lastName", "firstName", "term", "assigned", "completed", "total", "expectedRecommendation", "reviewerResults" ],
  notifyadmin: [ "applicantEmployeeId", "lastName", "firstName", "term", "earlyAction", 'onTime', 'inaccurateApplicationParent', 'classRankInacurrateParent', 'classRankActualSchoolReport', 'classRankActualTranscript', 'classRankEstimatedInaccurate', 'transcriptInaccurateParent', 'transcriptInaccurateApplicant', 'transcriptMissingGrades', 'reviewers' ],
  manageApplications: [ "applicantEmployeeId", "lastName", "firstName", "stage","campusDescription", "term", "residencyCategory", "earlyAction", "onTime", "applicationDate", "completedByDueDate", "documentCreatedDatetime", "academicProgramDescription", "academicPlan"]
};

export const adminReviewCommitteeList = [
  {
    id: "athlete",
    adminReview: false,
  },
  {
    id: "ccp-academy",
    adminReview: true,
  },
  {
    id: "ccp-academy-regional",
    adminReview: true,
  },    
  {
    id: "ccp-metro-hs",
    adminReview: true,
  },
  {
    id: "domestic-freshman",
    adminReview: false,
  },
  {
    id: "domestic-transfer",
    adminReview: true,
  },
  {
    id: "international-freshman",
    adminReview: false,
  },
  {
    id: "international-transfer",
    adminReview: true,
  },
  {
    id: "military",
    adminReview: true,
  },
  {
    id: "non-traditional",
    adminReview: true,
  },
];