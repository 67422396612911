import React from "react";
import { Mui } from "@osu/react-ui";

const useStyles = Mui.makeStyles((theme) => {
  return {
    width100: {
      width: "100%",
    },
    listItemText: {
      flexBasis: "35%",
      display: "flex",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.divider}`,
      paddingBottom: theme.spacing(2),
      marginLeft: theme.spacing(3),
    },
    dataList: {
      flexDirection: "row",
      flexWrap: "wrap",
    }
  };
});

const HighSchoolUnits = (props) => {
  const { studentData } = props;
  const classes = useStyles();

  const highSchoolUnitData = [
    {
      label: "Math",
      value: studentData?.data?.highSchool?.math,
    },
    {
      label: "Natural Science",
      value: studentData?.data?.highSchool?.naturalScience,
    },
    {
      label: "Foreign Language",
      value: studentData?.data?.highSchool?.foreignLanguage,
    },
    {
      label: "Art",
      value: studentData?.data?.highSchool?.art,
    },
    {
      label: "Social Science",
      value: studentData?.data?.highSchool?.socialScience,
    },
    {
      label: "Other",
      value: studentData?.data?.highSchool?.other,
    },
    {
      label: "English",
      value: studentData?.data?.highSchool?.english,  
    }   
  ];

  const otherAttributes = [
    {
      label: "Motivation",
      value: studentData?.data?.ccp?.motivation,
    },
    {
      label: "Maturity Level",
      value: studentData?.data?.ccp?.maturityLevel,
    },
    {
      label: "Academic Ability",
      value: studentData?.data?.ccp?.academicAbility,
    }
  ];
 
  return (
    <Mui.Box>
        <Mui.Box>
          <Mui.Typography
            variant="subtitle2"
            className="margin-top-3 margin-left-2"
            component="h3"
          >
            High School Units
          </Mui.Typography>
          <Mui.List
            className={`display-flex ${classes.width100} ${classes.dataList}`}
            component="dl"
          >
            {highSchoolUnitData.map(({ label, value }, index) => {
              return (
                <Mui.ListItemText
                  key={`list=items-text-hs-${index}`}
                  primary={label}
                  secondary={value}
                  className={classes.listItemText}
                  primaryTypographyProps={{
                    component: "dt",
                    className: "margin-right-2",
                    variant: "subtitle2",
                  }}
                  secondaryTypographyProps={{
                    component: "dd",
                    variant: "body2",
                    color: "textPrimary",
                  }}
                />
              );
            })}
          </Mui.List>
          <Mui.Typography
            variant="subtitle2"
            component="h3"
            className="margin-left-2"
          >
            Other Attributes
          </Mui.Typography>
          <Mui.List
            className={`display-flex ${classes.width100} ${classes.dataList}`}
            component="dl"
          >
            {otherAttributes.map(({ label, value }, index) => {
              return (
                <Mui.ListItemText
                  key={`list=items-text-hs-${index}`}
                  primary={label}
                  secondary={value}
                  className={classes.listItemText}
                  primaryTypographyProps={{
                    component: "dt",
                    className: "margin-right-2",
                    variant: "subtitle2",
                  }}
                  secondaryTypographyProps={{
                    component: "dd",
                    variant: "body2",
                    color: "textPrimary",
                  }}
                />
              );
            })}
          </Mui.List>
        </Mui.Box>
    </Mui.Box>
  );
};
export default HighSchoolUnits;