import React from "react";
import { Mui } from "@osu/react-ui";
import ACTScores from "./scoresAct";
import SATScores from "./scoresSat";
import BestTest from "./scoresBest";
import OtherScores from "./scoresOther";
import red from '@material-ui/core/colors/red';

const Scores = (props) => {
  const { studentData } = props;

  if (studentData?.data?.bestTest?.optional === true) {
    return (
      <Mui.Box>
        <Mui.Typography
        component="span"
        key={15}
        style={{ color: red[700], fontWeight: "bold", marginLeft: "1rem" }}
        variant="subtitle2"
        color="error"
      >
        Test Optional: Do not consider SAT or ACT scores as part of application.
      </Mui.Typography>
      <OtherScores studentData={studentData}/>
    </Mui.Box>   
    );
  } else {
    return (
      <Mui.Box>
        <BestTest studentData={studentData} />
        <ACTScores studentData={studentData} />
        <SATScores studentData={studentData} />
        <OtherScores studentData={studentData}/>
      </Mui.Box>
    );
  }
};

export default Scores;
