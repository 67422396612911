import { Mui } from '@osu/react-ui';
import useStyles from '../../dd-styles';
import Chip from './scoresChip';

const OtherScores = (props) => {
  const { studentData } = props;
  const classes = useStyles();

  return (
    <>
      {studentData?.data?.scores?.toefl?.length > 0 ? (
        <div>
          <Mui.Typography tabindex="0" aria-label='TOE FULL' component="h3" variant="h6" key={1}>
            TOEFL
          </Mui.Typography>
          {studentData?.data?.scores?.toefl?.map?.((item, index) => (
            <dl title="TOEFL" key={index} className={classes.dl}>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Date'}
                  childLabel={item.map((item) =>
                    new Date(item.date).toLocaleDateString()
                  )}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Total'}
                  childLabel={item.map((item) => item.total)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Listening'}
                  childLabel={item.map((item) => item.listening)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Reading'}
                  childLabel={item.map((item) => item.reading)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Speaking'}
                  childLabel={item.map((item) => item.speaking)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Writing'}
                  childLabel={item.map((item) => item.writing)}
                />
              </dd>
              <Mui.Divider />
            </dl>
          ))}
        </div>
      ) : (
        <></>
      )}
      {studentData?.data?.scores?.ielts?.length > 0 ? (
        <div>
          <Mui.Typography tabindex="0" aria-label='I E L T S' component="h3" variant="h6">
            IELTS
          </Mui.Typography>
          {studentData?.data?.scores?.ielts?.map?.((item, index) => (
            <dl title="IELTS" key={index} className={classes.dl}>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Date'}
                  childLabel={item.map((item) => item.date)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Listening'}
                  childLabel={item.map((item) => item.listening)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Reading'}
                  childLabel={item.map((item) => item.reading)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Overall Band Score'}
                  childLabel={item.map((item) => item.overall)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Speaking'}
                  childLabel={item.map((item) => item.speaking)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Writing'}
                  childLabel={item.map((item) => item.writing)}
                />
              </dd>
              <Mui.Divider />
            </dl>
          ))}
        </div>
      ) : (
        <></>
      )}
      {studentData?.data?.scores?.duolingo?.length > 0 ? (
        <div>
          <Mui.Typography tabindex="0" aria-label='DUO LINGO' component="h3" variant="h6">
            Duolingo
          </Mui.Typography>
          {studentData?.data?.scores?.duolingo?.map?.((item, index) => (
            <dl title="Duolingo" key={index} className={classes.dl}>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Date'}
                  childLabel={item.map((item) =>
                    new Date(item.date).toLocaleDateString()
                  )}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Comprehension'}
                  childLabel={item.map((item) => item.comprehension)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Conversation'}
                  childLabel={item.map((item) => item.conversation)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'English Test'}
                  childLabel={item.map((item) => item.englishTest)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Literacy'}
                  childLabel={item.map((item) => item.literacy)}
                />
              </dd>
              <dd tabindex="0" className={classes.dd}>
                <Chip
                  label={'Productivity'}
                  childLabel={item.map((item) => item.productivity)}
                />
              </dd>
              <Mui.Divider />
            </dl>
          ))}
        </div>
      ) : (
        <></>
      )}
      {studentData?.data?.scores?.accuplacer?.length > 0 ? (
        <div>
          <Mui.Typography tabindex="0" aria-label='ACCUPLACER' component="h3" variant="h6">
            ACCUPLACER
          </Mui.Typography>
          {studentData?.data?.scores?.accuplacer?.map?.((item, index) => (
            <dl title="ACCUPLACER" key={index} className={classes.dl}>
              <dd tabindex="0" className={classes.dd}>
              <Chip
                label={'Date'}
                childLabel={item.map((item) =>
                  new Date(item.date).toLocaleDateString()
                )}
              /></dd>
              <dd tabindex="0" className={classes.dd}>
              <Chip
                label={'Next Gen Quantitative Reasoning'}
                childLabel={item.map((item) => item.reasoning)}
              /></dd>
              <dd tabindex="0" className={classes.dd}>
              <Chip
                label={'Next Gen Reading'}
                childLabel={item.map((item) => item.readingNextGen)}
              /></dd>
              <dd tabindex="0" className={classes.dd}>
              <Chip
                label={'Next Gen Writing'}
                childLabel={item.map((item) => item.writingNextGen)}
              /></dd>
              <Mui.Divider />
            </dl>
          ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
export default OtherScores;
