// Accessibility features -- allows keyboard-based navigation of recommendation history table
//
function handleKeyPresses(event) {
    const { expanded, setExpanded } = this;

    switch (event.key) {
        case "ArrowLeft": { collapseRow(expanded, setExpanded); moveFocus(-1); break; }
        case "ArrowRight": { expandRow(expanded, setExpanded); moveFocus(1); break; }
        case "ArrowUp": { moveFocusVertically(-1); break; }
        case "ArrowDown": { moveFocusVertically(1); break; }
        case "Home": { jumpTo(-1); break; }
        case "End": { jumpTo(1); break; }
        
        default: return true;
    }
    event.preventDefault();
    return false;
}

function isFocusedOnFirstTd() {
    return document.activeElement.tagName.toUpperCase() === "TD"
    && Array.from(document.activeElement.parentNode.children).findIndex(ele => ele === document.activeElement) === 0;
}

function isFocusedOnExpandCollapseButton() { return document.activeElement === document.activeElement.parentNode.querySelector("button"); }

function collapseRow(expanded, setExpanded) {  expanded && isFocusedOnFirstTd() && setExpanded(false); }
function expandRow  (expanded, setExpanded) { !expanded && isFocusedOnExpandCollapseButton() && setExpanded(true); }

function moveFocus(direction) {
    let currentElement = document.activeElement;
    if (currentElement.tagName.toUpperCase() === "BUTTON") { currentElement = currentElement.parentNode; }
    
    const nextMatchingElement = currentElement.tagName.toUpperCase(); // Should match the same tag
    if (!currentElement || !["TD", "DIV"].includes(nextMatchingElement)) { return; }
    
    const siblingElements = Array.from(currentElement.parentNode.children).filter(ele => ele.tagName.toUpperCase() === nextMatchingElement);
    const elementIndex = siblingElements.findIndex(ele => ele === currentElement) + direction;
    if (elementIndex < 0 || elementIndex >= siblingElements.length) { return; } // Nothing to be done, we've at the first/last element selectable for this row
    
    siblingElements[elementIndex].focus();
    
    const button = siblingElements[elementIndex].querySelector("button");
    if (button) { button.focus(); }
}

function moveFocusVertically(direction) {
    let currentElement = document.activeElement;
    if (["BUTTON", "SPAN"].includes(currentElement.tagName.toUpperCase())) { currentElement = currentElement.parentNode; }
    
    const currentElementTagName = currentElement.tagName.toUpperCase(); // Should match the same tag
    if (!currentElement || !["TD", "DIV"].includes(currentElementTagName)) { return; }
    
    let parentNode = currentElement;
    while (parentNode && parentNode.tagName !== "TR") { parentNode = parentNode.parentNode; }

    const currentRow = parentNode;
    let selectedIndex = Array.from(currentRow.querySelectorAll('[tabindex="0"]')).findIndex(ele => ele === document.activeElement);
    
    while (parentNode && parentNode.tagName !== "TABLE") { parentNode = parentNode.parentNode; }
    
    // Find the current TR index
    const allRows = Array.from(parentNode.querySelectorAll("tr")).filter(ele => ele.dataset.rowhasfocusables == "true");
    const currentIndex = allRows.findIndex(row => row === currentRow);
    
    // Find the next/previous TR
    const nextIndex = currentIndex + direction;
    if (nextIndex <= -1 || nextIndex >= allRows.length) { return; } // Nowhere to go, exit early

    // Select the appropriate field depending on what index we are in our current TR
    const nextRow = allRows[nextIndex];
    const selectableElements = Array.from(nextRow.querySelectorAll('[tabindex="0"]'));
    
    selectedIndex = Math.min(selectedIndex, selectableElements.length - 1);
    selectableElements[selectedIndex].focus();
}

function jumpTo(direction) {
    for (let i = 0; i < 25; i++) { moveFocus(direction); } // Move X columns left/right
}

export { handleKeyPresses };