import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  assignApplication,
  fetchStudent,
  getReviewers,
  saveReview,
  submitReview,
  unassignApplication,
  reportConflict,
  restartEvaluation,
  searchDocuments,
  getDocument,
  cleanupApplicantDetail,
  overrideRecommendation,
  submitTraining,
  toggleHoldForExport,
  toggleHoldForAdmin,
  getExpectedRecommendation,
  getTrainingConfig,
} from "../../actions-index";
import Applicants from "../components";

const mapStateToProps = (state) => {
  return {
    userId: state?.authentication?.user?.osuid,
    student: state?.studentData?.student,
    reviewers: state?.committeeReviewerReducer?.reviewers,
    assignState: state?.assignApplicationReducer?.assignApplication,
    reportConflict: state?.reportConflictReducer?.reportConflict,
    unassignState: state?.unassignApplicationReducer?.unassignApplication,
    restartState: state?.restartApplicationReducer?.restartApplication,
    submitState: state?.submitDetailsReducer?.submitRecommendation,
    applicationDocuments: state?.studentData?.applicationDocuments,
    documentFiles: state?.studentData?.documentFiles,
    trainingConfig: state.createTrainingReducer?.createTraining,
    toggleHoldForExportState: state?.toggleHoldForExportReducer?.toggleHoldForExport,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStudent: (committeeId, applicantEmployeeId, admitTerm) =>
      dispatch(fetchStudent(committeeId, applicantEmployeeId, admitTerm)),
    getReviewers: (committeeId) => dispatch(getReviewers(committeeId)),
    getExpectedRecommendation: (applicantEmployeeId, committeeId, term) => dispatch(getExpectedRecommendation(applicantEmployeeId, committeeId, term)),
    saveReview: (
      id,
      students,
      decision,
      compChecks,
      noncompChecks,
      documentNotes,
      evaluationNotes,
      otherNotes
    ) =>
      dispatch(
        saveReview(
          id,
          students,
          decision,
          compChecks,
          noncompChecks,
          documentNotes,
          evaluationNotes,
          otherNotes
        )
      ),
    submitReview: (
      id,
      students,
      decision,
      compChecks,
      noncompChecks,
      documentNotes,
      evaluationNotes,
      otherNotes
    ) =>
      dispatch(
        submitReview(
          id,
          students,
          decision,
          compChecks,
          noncompChecks,
          documentNotes,
          evaluationNotes,
          otherNotes
        )
      ),
    assignApplication: (
      applicantEmployeeId,
      committeeId,
      term,
      readerEmployeeId,
      readerFirstName,
      readerLastName,
      reviewerCompletedByDueDate
    ) =>
      dispatch(
        assignApplication(
          applicantEmployeeId,
          committeeId,
          term,
          readerEmployeeId,
          readerFirstName,
          readerLastName,
          reviewerCompletedByDueDate
        )
      ),
    unassignApplication: (
      applicantEmployeeId,
      committeeId,
      term,
      reviewerEmployeeId
    ) =>
      dispatch(
        unassignApplication(
          applicantEmployeeId,
          committeeId,
          term,
          reviewerEmployeeId
        )
      ),
    reportConflict: (
      applicantEmployeeId,
      committeeId,
      term,
      reviewerEmployeeId
    ) =>
      dispatch(
        reportConflict(
          applicantEmployeeId,
          committeeId,
          term,
          reviewerEmployeeId
        )
      ),
    restartEvaluation: (
      applicantEmployeeId,
      committeeId,
      term
    ) =>
      dispatch(
        restartEvaluation(
          applicantEmployeeId,
          committeeId,
          term
        )
      ),
    searchDocuments: (
      applicantEmployeeId,
      admitTerm, 
      committeeId,
      isTraining,
      queryKey,
    ) =>
      dispatch(
        searchDocuments(
          applicantEmployeeId,
          admitTerm, 
          committeeId,
          isTraining,
          queryKey,
        )
      ),
    getDocument: (
      documents,
      admitTerm,
      applicantEmployeeId,
      committeeId,
      queryKey
    ) =>
      dispatch(
        getDocument(
          documents,
          admitTerm,
          applicantEmployeeId,
          committeeId,
          queryKey
        )
      ),
    overrideRecommendation: ( applicantEmployeeId, committeeId, term, recommendation, restriction, comments,) => 
      dispatch( overrideRecommendation( applicantEmployeeId, committeeId, term, recommendation, restriction, comments,)),
    submitTraining: (id, students, decision, compChecks, noncompChecks, documentNotes, evaluationNotes, otherNotes, restriction, isTraining) =>
      dispatch(submitTraining(id, students, decision, compChecks, noncompChecks, documentNotes, evaluationNotes, otherNotes, restriction, isTraining)),
    cleanup: () => dispatch(cleanupApplicantDetail()),
    toggleHoldForExport: (applicantEmployeeId, committeeId, term, holdForExportArg) => {
      dispatch(toggleHoldForExport(applicantEmployeeId, committeeId, term, holdForExportArg))
    },
    toggleHoldForAdmin: (applicantEmployeeId, committeeId, term, holdForAdminArg) => {
      dispatch(toggleHoldForAdmin(applicantEmployeeId, committeeId, term, holdForAdminArg))
    },
    getTrainingConfig: (applicantEmployeeId, committeeId, term) => dispatch(getTrainingConfig(applicantEmployeeId, committeeId, term))
  };
};



export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Applicants)
);
