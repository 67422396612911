import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Dashboard from "../components";
import { getCommitteeList, getInProgress, updateNextApp, cleanupDashboardNextApp } from "../../actions-index";

const mapStateToProps = (state, ownProps) => {
  return {
    committeesData: state?.DashboardReducer?.committees,
    committeeLoading: state?.DashboardReducer?.loading,
    error: state?.DashboardReducer?.error,
    user: state?.authentication?.user,
    assignedData: state?.InProgressReducer?.progress?.assigned,
    pastDueData: state?.InProgressReducer?.progress?.pastDue,
    trainingData: state?.InProgressReducer?.progress?.trainings,
    progressData: state?.InProgressReducer?.progress?.inProgress, 
    dashInprogress: state?.InProgressReducer?.progress?.inProgress?.dashInprogress,
    progressLoading: state?.InProgressReducer?.loading,
    nextAppData: state?.NextAppReducer?.nextApp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommitteeList: () => dispatch(getCommitteeList()),
    getInProgress: () => dispatch(getInProgress()),
    updateNextApp: (committeeId) => dispatch(updateNextApp(committeeId)), 
    cleanup: () => dispatch(cleanupDashboardNextApp()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);
