import { Mui } from '@osu/react-ui';
import { STATUS } from '../../util/constants';
import { useMemo } from 'react';

const HoldSwitch = ({ applicationStatus, committee, admitTerm, studentData, holdForExport, toggleHoldForExport, toggleHoldForAdmin }) => {
  const holdForAdmin = studentData?.data?.holdForAdmin;

  const getLabel = useMemo(() => {
    switch (applicationStatus) {
      case STATUS.ADMINREVIEW:
        return 'Hold for Information';
      case STATUS.PENDINGAPPROVAL:
        return 'Hold for Admin';
      default:
        return '';
    }
  }, [applicationStatus]);

  const getDefaultChecked = useMemo(() => {
    switch (applicationStatus) {
      case STATUS.ADMINREVIEW:
        return holdForExport;
      case STATUS.PENDINGAPPROVAL:
        return holdForAdmin;
      default:
        return false;
    }
  }, [applicationStatus, holdForExport, holdForAdmin]);

  const handleOnChange = (val) => {
    switch (applicationStatus) {
      case STATUS.ADMINREVIEW:
        toggleHoldForExport(studentData?.data?.demographics?.applicantEmployeeId, committee, admitTerm, val.target.checked)
        break;
      case STATUS.PENDINGAPPROVAL:
        toggleHoldForAdmin(studentData?.data?.demographics?.applicantEmployeeId, committee, admitTerm, val.target.checked)
        break;
      default:
        return null;
    }
  }

  return (
    <Mui.FormGroup style={{paddingBottom: '.2rem'}}>
      <Mui.FormControlLabel
        control={
          <Mui.Switch color="primary" defaultChecked={getDefaultChecked}
            onChange={(val) => {
              handleOnChange(val)
            }} inputProps={{ 'aria-label': getLabel }} />}
        label={getLabel}
      />
    </Mui.FormGroup>
  )

}
export default HoldSwitch;