import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Mui } from '@osu/react-ui';
import React from 'react';
import { COMMITTEE_COLOR_MAP } from '../../util/constants';
import { FiberManualRecord } from "@material-ui/icons";


const useStyles = Mui.makeStyles((theme) => ({
  
  cardChild: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.12rem 2rem',
    justifyContent: 'space-evenly',
    ChipLabel: {
      color: "black",
      display: "flex",
      alignItems: "center",
    },
  },
  cardText: {
    fontSize: "1rem",
  },
  pastDue: {
    fontSize: "1rem",
    color: "#BB0000",
    fontWeight: "bold"
  },

}));


export const PullCard = ({ committee, updateNextApp, disableButton, setDisableButton, reviewCountNew, reviewCountInProgress, inProgressLength, cardLink, completed, pastDue, groups, style }) => {
  const classes = useStyles();
  const handleReadNextApp = () => {
    updateNextApp(committee?.committeeId); //calls lambda get-next-app   
    setDisableButton(true);
  }
  return (
    <Mui.Box
      aria-label={`committee card for ${committee?.committeeName}`}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...style
      }}
    >
      <Mui.Paper
        className={classes.cardChild}
        style={{
          width: '100%',
        }}
      >
        <Mui.Box
        className={'margin-top-2'}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Mui.Chip
            style={{
              border: `1px solid ${COMMITTEE_COLOR_MAP[committee?.committeeId]
                }`,
            }}
            label={
              <Mui.Typography className={classes.ChipLabel}>
                <FiberManualRecord
                  style={{
                    color: COMMITTEE_COLOR_MAP[committee?.committeeId],
                    verticalAlign: "middle", // Add verticalAlign property
                  }}
                />
                {committee?.committeeName}
              </Mui.Typography>
            }
            variant="outlined"
          />
          {(reviewCountNew + reviewCountInProgress) <= 0 && <CheckCircleIcon style={{ color: '#1D5838' }} />}
        </Mui.Box>
        <Mui.Box className="margin-left-2 padding-top-2">

        <Mui.Typography
          className={classes.cardText}
          color="textSecondary"
          // component="p"
        > {reviewCountNew} New</Mui.Typography>

        <Mui.Typography className={classes.cardText} color="textSecondary"> {reviewCountInProgress} In Progress</Mui.Typography>
        <Mui.Typography className={pastDue > 0 ? classes.pastDue : classes.cardText} color="textSecondary"> {pastDue} Past Due</Mui.Typography>
            {(reviewCountNew + reviewCountInProgress <= 0) ? 
          <Mui.Button style={{padding: '0'}} disabled={true} color="primary"><Mui.Typography style={{ fontWeight: 'bold', fontSize: '1rem', color: '#1D5838' }}>ASSIGNMENT COMPLETE</Mui.Typography> </Mui.Button>
          : (reviewCountNew >= 1) ?
          <Mui.Typography style={{ fontSize: '1rem' }}>
          <Mui.Button style={{padding: '0'}} disabled={disableButton} color="primary" onClick={handleReadNextApp}>{'READ NEXT APPLICATION'}</Mui.Button>
          </Mui.Typography> : 
          <Mui.Button style={{padding: '0'}} disabled={true} color="primary"><Mui.Typography  style={{ fontWeight: 'bold', fontSize: '1rem', visibility: 'hidden' }}>ASSIGNMENT COMPLETE</Mui.Typography> </Mui.Button>
          }
        </Mui.Box>
      </Mui.Paper>
    </Mui.Box>
  );
};
