import Demographics from "./demographics";
import Scores from "./Scores/scores";
import CollegePrep from "./collegePrep";
import Documents from "./documents";
import PreviousUniversity from "./previousUniv";
import Qualities from "./Details/qualities";
import Notes from "./Details/notes";
import History from "./History/history";
import HighSchool from "./highschool";
import HighSchoolUnits from "./highschoolUnits";
import Recommendation from "./recommendation";

const sections =
{
  sections: [
    {
      id: "history",
      Component: History,
      heading: "Recommendation History",
      sortPriority: 1,
      defaultCollapsed: [],
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "admin-reviewer", "reviewer-plus", "slt-read-only"],
      // Need to hide this for readers looking at the training section
      customFilter: (customData) => !customData.isTraining || customData.authDetails.ADMIN
    },
    {
      id: "demographics",
      Component: Demographics,
      heading: "Demographics",
      sortPriority: 2,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "highschool",
      Component: HighSchool,
      heading: "High School",
      sortPriority: 3,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "collegePrep",
      Component: CollegePrep,
      heading: "College Prep Units",
      sortPriority: 4,
      committees: [
        "athlete",
        "military",
        "non-traditional",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "highschoolUnits",
      Component: HighSchoolUnits,
      heading: "High School Units",
      sortPriority: 5,
      committees: [
        "ccp-metro-hs",
        "ccp-academy",
        "ccp-academy-regional"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "scores",
      Component: Scores,
      heading: "Test Scores",
      sortPriority: 6,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "universityInfo",
      Component: PreviousUniversity,
      heading: "Previous University",
      sortPriority: 7,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "documents",
      Component: Documents,
      heading: "Documents",
      sortPriority: 8,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus", "slt-read-only"]
    },
    {
      id: "qualities",
      Component: Qualities,
      heading: "Attributes",
      sortPriority: 9,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus"],
      // Only show when they're a reviewer OR if they're an admin + ADMINREVIEW
      customFilter: (customData) => customData.authDetails.REVIEWER || (customData.authDetails.ADMIN && customData.applicationStatus === "ADMINREVIEW")
    },
    {
      id: "otherNotes",
      Component: Notes,
      heading: "Administrative Notes",
      sortPriority: 10,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus"],
      // Only show when they're a reviewer OR if they're an admin + ADMINREVIEW
      customFilter: (customData) => customData.authDetails.REVIEWER || (customData.authDetails.ADMIN && customData.applicationStatus === "ADMINREVIEW")
    },
    {
      id: "recommendation",
      Component: Recommendation,
      heading: "Recommendation",
      sortPriority: 11,
      committees: [
        "athlete",
        "domestic-freshman",
        "international-freshman",
        "domestic-transfer",
        "international-transfer",
        "military",
        "non-traditional",
        "ccp-academy",
        "ccp-academy-regional",
        "ccp-metro-hs"
      ],
      authorization: ["admin", "reviewer", "admin-reviewer", "reviewer-plus"]
    },
  ],

  checkDefaultCollapsed: function (defaultCollapsed, authDetails) {
    let view = this.convertView(authDetails);

    if (!view || !defaultCollapsed)
      return;

    return defaultCollapsed.includes(view);
  },

  convertView: function (authDetails, location) {
    let view;

    if (location?.pathname?.includes('view') && authDetails.SLT_READ_ONLY)
      return "slt-read-only"

    if (authDetails.ADMIN_REVIEWER)
      view = "admin-reviewer";
    else if (authDetails.ADMIN)
      view = "admin";
    else if (!authDetails.INITIAL_APPROVER)
      view = "reviewer-plus"
    else if (authDetails.REVIEWER)
      view = "reviewer";
    
    return view;
  },

  filterByCommittee: function (sections, currentCommittee) {
    return sections.filter((sect) => sect.committees.includes(currentCommittee));
  },

  filterByAuth: function (sections, authDetails, location) {
    let view = this.convertView(authDetails, location);

    if (!view)
      return;

    return sections.filter((sect) => sect.authorization.includes(view));
  },
  filterAuth: function (authDetails, customData) {
    return this.filterCustom(this.filterByAuth(this.sections, authDetails, customData.location), customData);
  },
  filterCombined: function (authDetails, currentCommittee, customData) {
    return this.filterCustom(this.filterByAuth(this.filterByCommittee(this.sections, currentCommittee), authDetails), customData);
  },
  filterCustom: function (sections, customData = {}) {
    return sections.filter(section => typeof section?.customFilter !== "function" || section.customFilter(customData));
  }
};
export default sections;
