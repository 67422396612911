import { Skeleton } from '@material-ui/lab';
import { Mui, MuiLab } from '@osu/react-ui';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useAlertHandling } from '../../Common/hooks/useAlertHandling';
import { getSubmitRecommendationState } from '../../Common/redux/selectors';
import { ERROR_CONSTS, SEVERITY_LEVELS, STATUS, STATUSES } from '../../util/constants';
import Submit from './submit';

import ReactCanvasConfetti from 'react-canvas-confetti';
import { HOLD_FOR_EXPORT } from '../../actions-index';
import HoldSwitch from './hold-switch';
import useRoleAuthentication from '../../Common/hooks/useRoleAuthentication';

const useStyles = Mui.makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(6),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    success: {
      padding: theme.spacing(3),
    },
    select: {
      width: '20em',
      height: '3em',
      marginBottom: theme.spacing(2),
    },
    errorColor: {
      color: theme.palette.colors.red,
      fontWeight: 'bold',
    }
  };
});

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 10000,
};

const Recommendation = (props) => {
  const location = useLocation();
  const classes = useStyles();
  const authDetails = useRoleAuthentication()
  const submitState = useSelector((state) =>
    getSubmitRecommendationState(state)
  );
  const alert = useAlertHandling({ alert: submitState?.alert });
  

  const {
    encodedApps,
    nextApp,
    adminReason,
    setAdminReason,
    show,
    name,
    committee,
    handleCloseSuccess,
    handleAlert,
    submitError,
    notesError,
    handleSubmit,
    openSuccess,
    openAlertSuccess,
    handleDecision,
    decision,
    handleToggle,
    open,
    openAlert,
    handleCloseBackDrop,
    isOverrideDecision,
    studentData,
    noteState,
    noteDispatch,
    readOnly,
    notifyAdmin,
    userIsReader,
    toggleHoldForExport,
    toggleHoldForAdmin,
    admitTerm,
    applicationStatus,
    toggleHoldForExportState,
    areCheckboxesChecked,
    isTraining,
    submitErrorLabelRef,
  } = props;
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const [adminReviewLinks, setAdminReviewLinks] = useState({ reports: null, next: null });

  useEffect(() => {
    if (searchParams){
      const from = searchParams.get("from");
      if (committee && !adminReviewLinks?.reports) {
        setAdminReviewLinks({ ...adminReviewLinks, reports: `/${from}/${committee}` })
      }
      if (nextApp && !adminReviewLinks?.next) {
        setAdminReviewLinks({ ...adminReviewLinks, next: nextApp ? `/applicant/${nextApp?.applicationid}/${nextApp?.committeeId}/${nextApp?.term}?todo=${encodedApps}&from=${from}` : null }) 
      }
    }
  }, [committee, searchParams, nextApp])

  const committeeButtonMap = (studentData?.data?.committeeDetails?.committeeOptions ?? []).sort((a, b) => a.sort - b.sort);
  //console.log("committeeButtonMap", committeeButtonMap);
  const adminRecReasons =
    studentData?.data?.administrativeRecommendationConfiguration
      ?.adminReasons ?? [];
  const holdForExport = studentData?.data?.holdForExport;

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 50,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 60,
    });
  }, [makeShot]);

  const closeSnackBar = () => {
    dispatch({
      type: HOLD_FOR_EXPORT,
      payload: {
        ...toggleHoldForExportState,
        status: '',
        alert: ERROR_CONSTS.UI.GENERIC.EMPTY_ALERT,
      },
    });
  }

  const action = (
    <div className="display-flex justify-content-space-evenly align-items-center">
    <Mui.Link
      component={Link}
      className="margin-right-2 margin-left-2"
      to={adminReviewLinks?.reports}
    >
      Reports
    </Mui.Link>
    {adminReviewLinks?.next && (
      <Mui.Link
        component={Link}
        // style={{margin: '0 1rem 0 1rem'}}
        className="margin-left-2 margin-right-3"
        to={adminReviewLinks?.next}
      >
        Next
      </Mui.Link>
    )}
  </div>
  );
  
  return (
    <>
     <Mui.Snackbar
        open={toggleHoldForExportState?.status === SEVERITY_LEVELS.SUCCESS}
        onClose={() => closeSnackBar()}
        // autoHideDuration={!nextApp ? 6000 : null}
        transition={Mui.Slide}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MuiLab.Alert action={action}>{toggleHoldForExportState?.alert?.MESSAGE}</MuiLab.Alert>
      </Mui.Snackbar>
    <Mui.Box style={{display: 'flex', flexDirection:'row'}}>
    {readOnly || notifyAdmin ? 
      <Mui.Box style={{display: 'flex', flexDirection: 'column'}}>
        {isTraining && ( // Notify Admin shouldn't show this
            <>
                <Mui.Typography>Your recommendation: {userIsReader?.recommendation}</Mui.Typography>
                <Mui.Typography>Expected recommendation: {studentData?.data?.expectedRecommendation}</Mui.Typography>
            </>
            )
        }
      </Mui.Box> :
      show ? (
        !committee ? (
          <Skeleton variant="rect" height="2rem" width="10rem" />
        ) : (
          <>
          <Mui.Box>
            {isOverrideDecision && (
              <>
                <Mui.FormControl
                  className={classes.formControl}
                  variant="outlined"
                >
                  <Mui.InputLabel id="administrative-recommendation-note-label">
                    Administrative Recommendation Reason
                  </Mui.InputLabel>
                  <Mui.Select
                    className={classes.select}
                    labelId="administrative-recommendation-note-label"
                    id="administrative-recommendation-note"
                    label="administrative-recommendation-note"
                    value={adminReason}
                    onChange={(event) => {
                      setAdminReason(event.target.value);
                    }}
                  >
                    {adminRecReasons?.map?.((item, index) => {
                      return (
                        <Mui.MenuItem key={index} value={item}>
                          {item}
                        </Mui.MenuItem>
                      );
                    })}
                  </Mui.Select>
                </Mui.FormControl>
                <br />
              </>
            )}
            <Mui.Box style={{display: 'flex'}}>
              <Mui.ButtonGroup aria-label="outlined button group">
                {committeeButtonMap.map((item, index) => {
                  const matchesDecision =
                    decision.toLowerCase() === item.value.toLowerCase();
                  return (
                    <Mui.Button
                      key={index}
                      onClick={(e) => handleDecision(item.value)}
                      color={matchesDecision && 'secondary'}
                      variant={matchesDecision && 'contained'}
                    >
                      {item.value}
                    </Mui.Button>
                  );
                })}
              </Mui.ButtonGroup>
              
              <Mui.Button
                variant="contained"
                color="primary"
                // color={(disable ? "primary" : (decision ? "primary" : "secondary"))}
                style={{ marginLeft: '20px', marginRight: '2rem' }}
                onClick={handleToggle}
              >
                Submit
              </Mui.Button>
            </Mui.Box>
          </Mui.Box>
          </>
        )
      ) : (
        <Mui.Box
        // style={{
          //   display: 'flex',
          //   justifyContent: 'center',
          //   width: decision.includes("Admit with Restrictions") || decision.includes("Request Additional Information") ? '50%' : '20%',
          // }}
        >
          <Mui.Typography className='margin-right-5' style={{paddingTop: '.3rem'}}>
            {decision}
          </Mui.Typography>
          {!decision && !studentData?.data?.demographics?.status?.includes(STATUSES.CANCELLED) && <Mui.Typography>{studentData?.data?.demographics?.inprogressStatus}</Mui.Typography>}
          {studentData?.data?.demographics?.status?.includes(STATUSES.CANCELLED) && <Mui.Typography>{studentData?.data?.demographics?.reason ?? 'Review Workflow Cancelled'}</Mui.Typography>}
        </Mui.Box>
      )}
    <Mui.Box style={{display: 'flex', alignItems: 'flex-end'}}>
    {(!readOnly && !notifyAdmin) && authDetails?.ADMIN === true && committee && (applicationStatus === STATUS.ADMINREVIEW || applicationStatus === STATUS.PENDINGAPPROVAL) && <HoldSwitch applicationStatus={applicationStatus} committee={committee} admitTerm={admitTerm} studentData={studentData} holdForExport={holdForExport} toggleHoldForExport={toggleHoldForExport} toggleHoldForAdmin={toggleHoldForAdmin} />}
    </Mui.Box>

      {toggleHoldForExportState?.alert?.SEVERITY === SEVERITY_LEVELS.ERROR && <Mui.Typography
        style={{ color: 'red', textAlign: 'center', margin: '1rem' }}
      >
        {toggleHoldForExportState?.alert?.MESSAGE}
      </Mui.Typography>}
     
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <Submit
        open={open}
        handleCloseBackDrop={handleCloseBackDrop}
        handleSubmit={handleSubmit}
        decision={decision}
        name={name}
        isOverrideDecision={isOverrideDecision}
        committee={studentData?.data?.committeeDetails?.committeeName}
        noteState={noteState}
        noteDispatch={noteDispatch}    
      />
        <Mui.Box aria-live="assertive" className='margin-top-2' id="submitErrorMessageBox" ref={submitErrorLabelRef} tabIndex="0">
        {submitError &&
          (isOverrideDecision && adminReason === '' ? (
            <Mui.Typography
              className={classes.errorColor}
            >
              Please choose a reason before clicking submit.
            </Mui.Typography>
          ) : (
            decision === '' ? (
              <Mui.Typography
                className={classes.errorColor}
              >
                Please choose a recommendation before clicking submit.
              </Mui.Typography>
            ) : 
              !areCheckboxesChecked && !isOverrideDecision ? (
                <Mui.Typography
                className={classes.errorColor}
                >
                Please choose at least one Attribute before recommendation submission.
                </Mui.Typography>
              ) : null
              ))}
        {notesError && (
          <Mui.Typography
          className={classes.errorColor}
          >
            You must select a subfield in Administrative Notes before clicking
            submit.
          </Mui.Typography>
        )}
        </Mui.Box>
      </Mui.Box>
    
      <Mui.Dialog
        open={openSuccess}
        onClose={handleCloseSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        {alert}

        <Mui.DialogContent>
          {submitState?.context?.length > 0 &&
          submitState?.alert?.SEVERITY !== SEVERITY_LEVELS?.WARNING ? (
            <>
              {submitState?.alert?.MESSAGE?.includes('Congratulations!') && fire()}
              
              <Mui.DialogContentText id="alert-dialog-description">
                {submitState?.alert?.MESSAGE}
              </Mui.DialogContentText>
            </>
          ) : (
            <>
            <div tabIndex="-1" aria-live="polite" id="alert-dialog-title-container" role="alert">
    </div>
            <Mui.DialogContentText id="alert-dialog-description">
              You have successfully submitted this application.
            </Mui.DialogContentText>
            </>
          )}
        </Mui.DialogContent>
        <Mui.DialogActions className="padding-2">
          <Mui.Button size="small" onClick={handleCloseSuccess}>
            Close
          </Mui.Button>
        </Mui.DialogActions>
      </Mui.Dialog>

      <Mui.Backdrop className={classes.backdrop} open={openAlert}>
        <Mui.Paper className={classes.root}>
          <Mui.Typography
            variant="h6"
            component="p"
            style={{ marginBottom: '1rem' }}
          >
            Alert Administrator
          </Mui.Typography>
          <Mui.Typography variant="body2">
            You are alerting an administrator that this application has a
            problem.
          </Mui.Typography>
          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Mui.Button
              style={{ marginRight: '1rem' }}
              variant="outlined"
              size="small"
              onClick={handleCloseBackDrop}
            >
              Cancel
            </Mui.Button>
            <Mui.Button
              aria-live='polite'
              variant="contained"
              color="primary"
              size="small"
              onClick={handleAlert}
            >
              Alert Administrator
            </Mui.Button>
          </div>
        </Mui.Paper>
      </Mui.Backdrop>

      <Mui.Backdrop className={classes.backdrop} open={openAlertSuccess}>
        <Mui.Paper className={classes.success}>
          <Mui.Typography
            variant="h6"
            component="p"
            style={{ marginBottom: '1rem' }}
          >
            Success
          </Mui.Typography>
          <Mui.Typography variant="body2">
            You have successfully alerted the administrator regarding this
            application.
          </Mui.Typography>
          <div
            style={{
              marginTop: '2rem',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Mui.Button
              style={{ marginRight: '1rem' }}
              variant="outlined"
              size="small"
              onClick={handleCloseSuccess}
            >
              Close
            </Mui.Button>
          </div>
        </Mui.Paper>
      </Mui.Backdrop>
    </>
  );
};

export default Recommendation;
