import RestrictionsNotes from '../Applicant/components/RestrictionsNotes';

export const getAdditionalOptionsMap = ({ type, payload }) => {
    switch(type) {
        case 'RESTRICTIONS':
            return <RestrictionsNotes noteDispatch={payload.noteDispatch}/>
        default:
            return null
    }

} 