import React from "react";
import { Mui } from "@osu/react-ui";
import CompQualities from "./compQualities";
import NoncompetitiveQualities from "./noncompQualities";

const Qualities = (props) => {
  const {
    studentData,
    renderInputControls
  } = props;

  return (
    <div style={{ display: "flex" }}>
      <Mui.Box>
        <CompQualities
          studentData={studentData}
          renderInputControls={renderInputControls}
        />
      </Mui.Box>
      <Mui.Box>
        <NoncompetitiveQualities
          studentData={studentData}
          renderInputControls={renderInputControls}
        />
      </Mui.Box>
    </div>
  );
};
export default Qualities;
