import RefreshIcon from '@material-ui/icons/Refresh';
import Skeleton from "@material-ui/lab/Skeleton";
import { Mui } from "@osu/react-ui";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { STATUS } from "../../util/constants";
import red from '@material-ui/core/colors/red';

const useStyles = Mui.makeStyles((theme) => {
  return {
    list: {
      textAlign: "right",
      width: "95%",
    },
    listItem: {
      padding: 0,
    },
    title: {
      width: "100%",
    },
  };
});

const Documents = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const { applicationDocuments, getDocument, documentFiles, studentData, refreshDocuments } = props;
  const admitTerm = studentData?.data?.demographics?.admitTerm?.key;
  const applicantEmployeeId = studentData?.data?.demographics?.applicantEmployeeId  
  const committeeId = studentData?.data?.demographics?.committeeId;
  const isReadOnlyView = location?.pathname?.includes('/view/');
  const isTraining = location?.pathname?.includes('/training/');
  const queryKey = isReadOnlyView ? 'getApplicationDocumentReadOnly' : isTraining ? 'getApplicationTrainingDocument' : 'getApplicationDocument'

  return (<>
    {Array.isArray(applicationDocuments?.data) && <Mui.List className={classes.list}>
      {applicationDocuments?.status === STATUS.LOADING ? <>
        <Skeleton variant="rect" height="2em" width="100%"></Skeleton>
        <br />
        <Skeleton variant="rect" height="2em" width="100%"></Skeleton>
        <br />
        <Skeleton variant="rect" height="2em" width="100%"></Skeleton>
        <br />
        <Skeleton variant="rect" height="2em" width="100%"></Skeleton>
        <br />
        <Skeleton variant="rect" height="2em" width="100%"></Skeleton>
      </>
        :
        applicationDocuments?.data?.map((item) => (
          <div key={item.documentId}>
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                <>
                <Mui.Box style={{display: 'flex', alignItems: 'center'}}>
                  <Mui.Button color="primary" onClick={() => getDocument([{ documentId: item.documentId, documentName: item.documentType }], admitTerm, applicantEmployeeId, committeeId, queryKey)}>{item.displayLabel}
                  </Mui.Button>
                  {documentFiles?.status === STATUS.LOADING && documentFiles?.documents?.length === 1 && documentFiles?.documents?.find((doc) => doc?.documentId === item?.documentId) &&
                  <Mui.CircularProgress size={20} style={{ marginLeft: "2rem" }} />}
                  </Mui.Box>
                </>
                }
              />
              
              { documentFiles?.data?.some?.(document => document.documentId === item.documentId && document.status == STATUS.ERROR) &&
                <Mui.ListItemText
                  primary={ <Mui.Typography variant="overline">Failed to Load</Mui.Typography> }
                  className={classes.list} /> }
              <Mui.ListItemText
                primary={item.documentDate}
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
          </div>
        ))}
        
        <OpeningAllDocumentsSpinner showSpinner={documentFiles?.status === STATUS.LOADING && documentFiles?.documents?.length > 1} />
        
        <OpenAllDocumentsButton showButton={!(documentFiles?.status === STATUS.LOADING && documentFiles?.documents?.length > 1) && applicationDocuments?.data.length}
            getDocument={getDocument} 
            applicationDocuments={applicationDocuments}
            admitTerm={admitTerm}
            applicantEmployeeId={applicantEmployeeId}
            committeeId={committeeId}
            queryKey={queryKey}
        />
        
        <NoDocumentsFound showMessage={applicationDocuments?.status !== STATUS.LOADING && applicationDocuments?.status !== STATUS.ERROR && applicationDocuments?.data.length <= 0} />
        
        <DocumentsError showError={applicationDocuments?.status === STATUS.ERROR} refreshDocuments={refreshDocuments} />

        <Mui.Box style={{display: 'flex', justifyContent: 'flex-start'}}>
      </Mui.Box>
    </Mui.List> }
  </>
  );
};

function OpeningAllDocumentsSpinner({showSpinner}) {
    if (!showSpinner) { return <></>; }
    
    return (
        <Mui.Box style={{display: 'flex', justifyContent: 'flex-start'}}>
            <Mui.CircularProgress size={30} style={{ float: "left", marginLeft: "2rem" }} className="margin-right-3 margin-top-3" />
        </Mui.Box>
    );
}

function OpenAllDocumentsButton({showButton, getDocument, applicationDocuments, admitTerm, applicantEmployeeId, committeeId, queryKey}) {
    if (!showButton) { return <></>; }
    
    return (
        <Mui.Box style={{display: 'flex', justifyContent: 'flex-start'}}>
            <Mui.Button
                variant="outlined"
                style={{ marginTop: "1rem" }}
                onClick={() => {
                    let requests = [];
                    for (let doc of applicationDocuments?.data) {
                        requests.push({ documentId: doc.documentId, documentName: doc.documentType })
                    }
                    getDocument(requests, admitTerm, applicantEmployeeId, committeeId, queryKey);
                }}
                >Open All</Mui.Button>
        </Mui.Box>
    );
}

function NoDocumentsFound({showMessage}) {
    if (!showMessage) { return <></>; }
    
    return (
        <Mui.Box style={{textAlign: "left" }}>
            No documents found for this application.
        </Mui.Box>
    );
}

function DocumentsError({showError, refreshDocuments}) {
    if (!showError) { return <></>; }
    
    return (
        <Mui.Box style={{textAlign: "left" }}>
          <Mui.Typography
            component="span"
            key={15}
            style={{ color: red[700], fontWeight: "bold", marginLeft: "1rem" }}
            variant="subtitle2"
            color="error"
          >
            Error loading documents. Please try again.           
            </Mui.Typography>
            <Mui.Button color="primary" variant="contained" onClick={refreshDocuments} style={{ float: "right" }}><RefreshIcon/></Mui.Button>
        </Mui.Box>
    );
}

export default Documents;
