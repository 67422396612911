import React from "react";
import { Mui } from "@osu/react-ui";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useRoleAuthentication from "../../Common/hooks/useRoleAuthentication";

const useStyles = Mui.makeStyles((theme) => {
  return {
    list: {
      textAlign: "right",
      width: "95%",
    },
    listItem: {
      padding: 0,
    },
    title: {
      width: "100%",
    },
  };
});

const Demographics = (props) => {
  const classes = useStyles();
  const { studentData } = props;
  const authDetails = useRoleAuthentication();
  
  return (
    <>
      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={4}>
          <Mui.List className={classes.list}>
          {authDetails.ADMIN && (
            <>
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Early Action
                  </Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={
                  studentData?.data?.demographics?.earlyAction === true ? "Yes" : "No"
                }
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
            </>
            )}
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>Campus</Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={studentData?.data?.demographics?.campus}
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Admit Term
                  </Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={studentData?.data?.demographics?.admitTerm?.value}
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Admit Type
                  </Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={studentData?.data?.demographics?.admitType}
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
            {authDetails.ADMIN && (<>
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Academic Program
                  </Mui.Typography>
                }
              />
              <div>
                <Mui.Tooltip
                  title={studentData?.data?.demographics?.academicProgram?.value}
                  placement="top"
                >
                  <Mui.Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Mui.Typography
                      component="span"
                      style={{
                        padding: "5px",

                        cursor: "default",
                      }}
                    >
                      {studentData?.data?.demographics?.academicProgram?.key}
                    </Mui.Typography>
                    <InfoOutlinedIcon
                      color="primary"
                      style={{ fontSize: "12px" }}
                    />
                  </Mui.Box>
                </Mui.Tooltip>
              </div>
            </Mui.ListItem>
            <Mui.Divider />
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Academic Plan
                  </Mui.Typography>
                }
              />
              <div>
                <Mui.Tooltip title={studentData?.data?.demographics?.academicPlan?.value} placement="top">
                  <Mui.Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Mui.Typography
                      component="span"
                      style={{
                        padding: "5px",

                        cursor: "default",
                      }}
                    >
                      {studentData?.data?.demographics?.academicPlan?.key}
                    </Mui.Typography>
                    <InfoOutlinedIcon
                      color="primary"
                      style={{ fontSize: "12px" }}
                    />
                  </Mui.Box>
                </Mui.Tooltip>
              </div>
            </Mui.ListItem>
            </>)}
          </Mui.List>
        </Mui.Grid>

        <Mui.Grid item xs={4}>
          <Mui.List className={classes.list}>
            {authDetails.ADMIN && (
            <>
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Regular Decision
                  </Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={
                  studentData?.data?.demographics?.onTime === true ? "Yes" : "No"
                }
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
            </>
            )}
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Low Socio-Economic
                  </Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={
                  studentData?.data?.demographics?.lowSocioeconomic === true
                    ? "Yes"
                    : "No"
                }
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />

            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>First Gen</Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={
                  studentData?.data?.demographics?.firstGeneration ? "Yes" : "No"
                }
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />
          </Mui.List>
        </Mui.Grid>

        <Mui.Grid item xs={4}>
          <Mui.List className={classes.list}>
            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Country of Citizenship
                  </Mui.Typography>
                }
              />
              {studentData?.data?.countryOfCitizenship?.map((item, index) => (
                <Mui.ListItemText
                  key={index}
                  primary={item}
                  className={classes.list}
                />
              ))}
            </Mui.ListItem>
            <Mui.Divider />

            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>
                    Country of Home Address
                  </Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={
                  studentData?.data?.demographics?.homeCountry
                }
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />

            <Mui.ListItem className={classes.listItem}>
              <Mui.ListItemText
                className={classes.title}
                primary={
                  <Mui.Typography variant="subtitle2" component='h3'>Ohio Resident</Mui.Typography>
                }
              />
              <Mui.ListItemText
                primary={
                  studentData?.data?.demographics?.ohioResident
                  }
                className={classes.list}
              />
            </Mui.ListItem>
            <Mui.Divider />

            {authDetails.ADMIN ? (
              <>
                <Mui.ListItem className={classes.listItem}>
                  <Mui.ListItemText
                    className={classes.title}
                    primary={
                      <Mui.Typography variant="subtitle2" component='h3'>
                        Recruitment Category
                      </Mui.Typography>
                    }
                  />
                  <Mui.ListItemText
                    primary={studentData?.data?.demographics?.recruitmentCategory}
                    className={classes.list}
                  />
                </Mui.ListItem>
                <Mui.Divider />

                <Mui.ListItem className={classes.listItem}>
                  <Mui.ListItemText
                    className={classes.title}
                    primary={
                      <Mui.Typography variant="subtitle2" component='h3'>
                        Load Date
                      </Mui.Typography>
                    }
                  />
                  <Mui.ListItemText
                    primary={studentData?.data?.demographics?.applicationDate}
                    className={classes.list}
                  />
                </Mui.ListItem>
              </>
            ) : null}
          </Mui.List>
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export default Demographics;
