import { Mui } from "@osu/react-ui";
import React, { useState } from "react";
import { connect } from 'react-redux';
import { NOTES_STATES_CASES } from "../../NotesState";

const mapStateToProps = (state) => { return { notes: state?.studentData?.student?.data?.restrictions ?? [] }; }

const useStyles = Mui.makeStyles((theme) => {
    return {
      errorColor: {
        color: theme.palette.colors.red,
        fontWeight: 'bold',
      }
    };
  });

function RestrictionsNotes(props) {
    const classes = useStyles()
    const { notes } = props;
    const { noteDispatch } = props;
    const notesOptions = getNestedParentChildNotesOptions(notes);

    // State handling
    const [checkedOptions, setCheckedOptions] = useState({});
    const checkedState = {notes, checkedOptions, setCheckedOptions, noteDispatch};

    return (
        <>
            <Mui.Typography color="secondary">Restrictions:<span className={classes.errorColor}>*</span></Mui.Typography>
            <Mui.FormGroup>
                {notesOptions.map(renderNestedNotesOption.bind(checkedState))}
                {notesOptions.length === 0 && <strong><i style={{ color: "red" }}>Selectable Restrictions Notes Configuration is Missing!</i></strong>}
            </Mui.FormGroup>
        </>
    );
}

function getNestedParentChildNotesOptions(notes = []) {
    const notesOptions = notes?.filter?.(note => !note.parentLabel) ?? [];

    // Push child notes into parent notes
    notes.forEach(note => {
        if (!note.parentLabel) { return; } // Skip
        let parentNote = notesOptions.find(parentNote => parentNote.label === note.parentLabel) ?? {};
        parentNote.children = parentNote.children ?? []; // Make sure it's at least an array
        parentNote.children.push(note); // Add to end of list of sub notes
    });

    return notesOptions;
}

function renderNestedNotesOption(notesOption) {
    const {notes, checkedOptions} = this;
    const color = getColor(notes, notesOption, checkedOptions);

    return (
        <React.Fragment key={notesOption.label}>
            <Mui.FormControlLabel
                label={ <Mui.Typography color="secondary">{ notesOption?.label }</Mui.Typography> }
                control={
                    <Mui.Checkbox inputProps={{ "aria-label": notesOption?.label }}
                        checked={checkedOptions?.[notesOption.label]?.value ?? false}
                        onChange={evt => handleChange(evt, notesOption, this)}
                        name={notesOption?.label}
                        style={{ color, paddingLeft: notesOption.parentLabel ? "2rem" : ".5rem" }}
                    />
                }
            />
            {
                notesOption.children?.map?.(renderNestedNotesOption.bind(this)) // Render this parent's child notes if they exist
            }
        </React.Fragment>
    );
}

function getColor(notes, notesOption, checkedOptions) {
    const hasParent = notesOption.parentLabel ? true : false;
    const hasCheckedParent = checkedOptions[notesOption.parentLabel]?.value === true;
    const hasCheckedSiblings = notes.filter?.(note => note.parentLabel == notesOption.parentLabel).some?.(note => checkedOptions[note.label]?.value === true);
    
    // Flip to red only if: their parent is checked but none of their siblings are
    const color = !hasParent || !hasCheckedParent || hasCheckedSiblings ? "" : "red";
    // console.info("color", color, notesOption.label, hasParent, hasCheckedParent, hasCheckedSiblings);

    return color;
}

function handleChange(event, notesOption, checkedState) {
    const { notes, setCheckedOptions, noteDispatch } = checkedState;

    const checked = event.target.checked;
    const currentLabel = notesOption.label;
    const currentParentLabel = notesOption.parentLabel;
    const hasChildren = notes.some(note => note.parentLabel === currentLabel);
    
    let newState = { [notesOption.label]: { value: checked, hasChildren }, }; // Check/uncheck this note

    if (currentParentLabel) {
        newState = { ...newState, [currentParentLabel]: { value: checked, hasChildren: true } };
    }

    // Only allow one checkbox to be checked
    notes.forEach(note => { if (note.label !== currentLabel && note.label !== currentParentLabel) { newState[note.label] = false; } });

    setCheckedOptions(newState);
    updateParentNotesStateForSubmission(noteDispatch, newState)
}

function updateParentNotesStateForSubmission(noteDispatch, state) {
    const payload = { state };
    const type = NOTES_STATES_CASES.SET_RESTRICTIONS;
    noteDispatch({ type, payload });
}

export default connect(mapStateToProps, null)(RestrictionsNotes);